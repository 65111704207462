import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setSelectedCategory } from '../../actions/menuActions';
import CategoryListV1 from './CategoryListV1';
import CategoryListV2 from './CategoryListV2';

function MainCategories(props) {
  const { t, menu } = props;
  const mainCategories = menu.categories
    ?.filter((c) => parseInt(c.main_category) === 1 && parseInt(c.hidden_category) !== 1)
    .sort((a, b) => a.sequence - b.sequence);

  const mainCategoryListVersion = menu?.settings.MAIN_CATEGORY_LIST_VERSION;

  const navigate = useNavigate();

  const navigateToMenuDetails = (categoryId) => {
    props.setSelectedCategory(categoryId);
    navigate('menu');
  };

  const renderCategoryList = () => {
    if (mainCategoryListVersion === '1') {
      return <CategoryListV1 mainCategories={mainCategories} onCategorySelected={navigateToMenuDetails} />;
    } else if (mainCategoryListVersion === '2') {
      return <CategoryListV2 mainCategories={mainCategories} onCategorySelected={navigateToMenuDetails} />;
    } else {
      return <CategoryListV1 mainCategories={mainCategories} onCategorySelected={navigateToMenuDetails} />;
    }
  };

  return (
    <>
      {mainCategories && mainCategories.length > 0 && (
        <div className="section main-category">
          {menu?.settings?.SHOW_MAIN_CATEGORY_TTILE === 'true' && (
            <div className="title">
              <h3>{t('Main Categories')}</h3>
            </div>
          )}

          {renderCategoryList()}

          {menu?.settings.SHOW_BROWSE_ALL_MENU_BUTTON === 'true' && (
            <div className="actions">
              <div className="button-browse-all-menu">
                <Link to="menu" className="btn-secondary button">
                  {t('Browse menu')}
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSelectedCategory: setSelectedCategory,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MainCategories));
