import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import FormatPrice from './FormatPrice';
import OrderItemAttributes from './OrderItemAttributes';
import OrderItemAssociations from './OrderItemAssociations';

function PrintOrderView(props) {
  const { t, order, config } = props;
  const componentRef = useRef();

  const resolveShoppingCartItemProduct = (item) => {
    if (props.menu && props.menu.products) {
      return props.menu.products.find((p) => parseInt(item.productId) === parseInt(p.id));
    }
    return null;
  };

  const dir = config.currentLanguage && config.currentLanguage.dir;

  const calculateItemPrice = (item) => {
    let total = 0;
    total += parseFloat(item.price);
    if (item.associations) {
      item.associations.forEach((ass) => {
        total += parseFloat(ass.price);
      });
    }
    return total;
  };

  class ComponentToPrint extends React.Component {
    render() {
      return (
        <div className="section checkout-container" dir={dir}>
          <div className="order-header">
            <img alt="" src="https://static.getmenu.ps/storage/prod/accounts/4/media/1703379676_94608cbd6ed7068ee3fc.jpg" />
            <p>
              Pizaa Fix <br /> 09-7718820 <br />
              الطيبة - شارع سميح القاسم
            </p>

            <p>פירוט חשבון</p>

            <p className="order-number">
              {t('Order#')}: {order?.id}
            </p>
            {order?.table_number && (
              <p className="table-number">
                {t('Table Number')}: {order.table_number}
              </p>
            )}
          </div>
          <div className="order-items">
            {order?.items?.map((item, index) => {
              const product = resolveShoppingCartItemProduct(item);
              return (
                <div className="order-item" key={index}>
                  <div className="order-item-name-and-description">
                    <p className="order-item-name">{product?.name}</p>
                    <OrderItemAttributes item={item} />
                    {item.associations && item.associations.length > 0 && <OrderItemAssociations item={item} />}
                    {item.comments && (
                      <p className="order-item-comments">
                        <span className="comments-title">{t('Comments')}:</span> {item.comments}
                      </p>
                    )}
                  </div>
                  <p className="order-item-details">
                    <span>{item.quantity}</span> <span>x</span> <span>{calculateItemPrice(item).toFixed(2)}</span>
                  </p>
                </div>
              );
            })}
          </div>
          <div className="order-summary">
            <div className="order-summary-line order-total">
              <p className="line-title">{t('Order Total')}:</p>
              <p className="line-value">
                <FormatPrice value={order?.amount || 0} />
              </p>
            </div>
          </div>
          <p className="with-appetite-message">{t('With Appetite')}</p>
        </div>
      );
    }
  }

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <button className={props.buttonClassName}>
            <img src={props.buttonIcon} alt="" />
          </button>
        )}
        content={() => componentRef.current}
        copyStyles={true}
      />
      <div style={{ display: 'none' }}>
        <ComponentToPrint ref={componentRef} />
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    config: state.config,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PrintOrderView));
