import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { fetchShoppingCartDetails } from '../actions/shoppingCartActions';
import AddToCart from '../components/AddToCart';
import Header from '../components/Header';
import MiniCart from '../components/MiniCart';
import ProductDefiningAttribute from '../components/ProductDefiningAttribute';
import ProductFullImage from '../components/ProductFullImage';
import ProductPrice from '../components/ProductPrice';
import ProductReviews from '../components/ProductReviews';
import DefaultLayout from '../layout/DefaultLayout';
import MenuService from '../services/menu';
import UtilityService from '../services/utility';
import MerchandisingAssociations from '../components/MerchandisingAssociations';
import FooterNavigation from '../components/FooterNavigation';

function ProductDetailsContainer(props) {
  const { id, uuid } = useParams();
  const { t } = useTranslation();
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [selectedAssociations, setSelectedAssociations] = useState([]);

  const product = props.menu?.products?.find((p) => p.id == id);

  let availableItems = product?.items;
  const dineInOrTakeaway = props.config.dineInOrTakeaway;
  const dineInExtraCostEnabled = props.menu?.settings?.DINE_IN_EXTRA_COST_ENABLED;
  if (!!dineInExtraCostEnabled && dineInOrTakeaway === 'DINE_IN') {
    availableItems = availableItems?.filter((p) => UtilityService.isAllowToDineIn(p));
  }

  const resolveSelectedProductId = () => {
    if (product) {
      if (product.type === 'COMPLEX_PRODUCT') {
        if (availableItems.length === 1) {
          return availableItems[0].id;
        }
        let filterItems = product.items;
        selectedAttributes.forEach((element) => {
          filterItems = filterItems?.filter((i) => {
            for (let x = 0; x < i.attribute.length; x++) {
              if (i.attribute[x]['attribute_' + element.attributeId] == element.selectedValueId) {
                return true;
              }
            }
          });
        });
        if (selectedAttributes.length > 0 && filterItems.length > 0) {
          return filterItems[0].id;
        }
        return null;
      } else {
        return product.id;
      }
    }
    return null;
  };

  useEffect(() => {
    if (product && id) {
      MenuService.sendTrackEvent('PRODUCT_VIEW', { productId: id });
      window.scrollTo(0, 0);
    }
  }, [id, product]);

  return (
    <>
      <DefaultLayout className="template-v1">
        <div className="section product-details-container">
          <Header
            showBackButton={true}
            showShareButton={false}
            showLocalSwitch={false}
            showBusinessDetails={false}
            backURL={'/m/' + uuid + '/menu'}
          />
          {product && (
            <>
              <ProductFullImage product={product} />
              <div className="product-details">
                <div className="product-title">
                  <p>{product?.name}</p>
                </div>
                {props.menu?.settings?.RATE_ENABLED === 'true' && (
                  <div className="product-rating">
                    <StarRatings
                      rating={parseFloat(product.rate || 0)}
                      starDimension="25px"
                      starSpacing="8px"
                      starRatedColor="#5777ba"
                      numberOfStars={5}
                      name="rating"
                    />
                  </div>
                )}
                <div className="product-price">
                  <div className="product-offer-price">
                    <ProductPrice selectedItemId={resolveSelectedProductId()} product={product} />
                  </div>
                  <p className="product-list-price"></p>
                </div>
                {product?.description && (
                  <div className="product-description">
                    <p className="product-description-title">{t('Details')}:</p>
                    <p className="product-description-value">{product?.description}</p>
                  </div>
                )}

                {product && product.type === 'COMPLEX_PRODUCT' && availableItems.length > 1 && (
                  <ProductDefiningAttribute onChange={setSelectedAttributes} product={product} />
                )}

                {product && product.merchandisingAssociations && product.merchandisingAssociations.length > 0 && (
                  <MerchandisingAssociations onChange={setSelectedAssociations} product={product} />
                )}

                {props.menu?.settings?.ALLOW_TO_ORDER === 'true' && (
                  <div className="product-actions">
                    <AddToCart productId={resolveSelectedProductId()} showNote={true} merchandisingAssociations={selectedAssociations} />
                  </div>
                )}
                {props.menu?.settings?.REVIEW_ENABLED === 'true' && product && <ProductReviews product={product} />}
              </div>
            </>
          )}
        </div>
        {props.menu?.settings?.ALLOW_TO_ORDER === 'true' && <MiniCart />}
        {props.menu?.settings?.FOOTER_NAVIGATION_ENABLED === 'true' && <FooterNavigation />}
      </DefaultLayout>
    </>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    config: state.config,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchShoppingCartDetails: fetchShoppingCartDetails,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsContainer);
