import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { connect } from 'react-redux';
import { setConfig } from '../actions/configActions';
import ProductSort from './ProductSort';

function MenuFilters(props) {
  const { config } = props;

  const setLayout = (layout) => {
    props.setConfig({ productLayout: layout });
  };

  return (
    <div className="menu-filters-and-options">
      <div className="layout-switch">
        <img
          src="/assets/icon/small-grid-layout.svg"
          className={config.productLayout === 'small-grid' ? 'active' : ''}
          onClick={() => setLayout('small-grid')}
          alt=""
        />
        <img
          src="/assets/icon/large-grid-layout.svg"
          className={config.productLayout === 'large-grid' ? 'active' : ''}
          onClick={() => setLayout('large-grid')}
          alt=""
        />
        <img
          src="/assets/icon/list-layout.svg"
          className={config.productLayout === 'list' ? 'active' : ''}
          onClick={() => setLayout('list')}
          alt=""
        />
      </div>
      <ProductSort />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    config: state.config,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setConfig: setConfig,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuFilters);
