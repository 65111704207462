import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

function Coupon(prop) {
  const { t } = useTranslation();

  return (
    <div className="mini-cart-coupon">
      <input type="text" placeholder={t('Coupon Code')} />
      <button>{t('Apply Code')}</button>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    shoppingCart: state.shoppingCart,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Coupon);
