import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { MEDIA_BASE_URL } from '../../utils/constant';

function OrderHeader(props) {
  const { shoppingCart, t, menu } = props;

  const getMenuLogo = () => {
    let logo = '/assets/icon/default-menu-logo.svg';
    if (menu.logo) {
      logo = MEDIA_BASE_URL + menu.logo;
    }
    return logo;
  };

  return (
    <div className="order-header">
      <Link className="back-link" to="../menu">
        <img alt="" src="/assets/icon/back-arrow.svg" />
      </Link>
      <img alt="" src={getMenuLogo()} />
      <p className="order-header-title">{t('Order Review')}</p>
      <p className="order-number">
        {t('Order#')}: {shoppingCart?.id}
      </p>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    shoppingCart: state.shoppingCart,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderHeader));
