import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import UtilityService from '../services/utility';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { connect } from 'react-redux';

function ProductImage(props) {
  const { menu, enablePDPLink } = props;
  const [loaded, setLoaded] = useState(false);
  const displayDefaultImageEnabled = menu?.settings?.DISPLAY_DEFUALT_PRODUCT_IMAGE_ENABLED === 'true';

  const url = UtilityService.getProductImageURL(props.product, displayDefaultImageEnabled);

  return (
    <>
      {url && (
        <div className="product-image">
          <div className="product-image-wrapper">
            {enablePDPLink && (
              <Link to={'product/' + props.product.id}>
                {!loaded && <Skeleton borderRadius={20} height={140} />}
                <img alt="" style={{ display: loaded ? 'block' : 'none' }} src={url} onLoad={() => setLoaded(true)} />
              </Link>
            )}
            {!enablePDPLink && (
              <>
                {!loaded && <Skeleton borderRadius={20} height={140} />}
                <img alt="" style={{ display: loaded ? 'block' : 'none' }} src={url} onLoad={() => setLoaded(true)} />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
  };
}

export default connect(mapStateToProps)(ProductImage);
