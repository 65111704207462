import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchShoppingCartDetails } from '../actions/shoppingCartActions';
import LocalSwitchModal from './LocalSwitchModal';

function FooterNavigation(props) {
  const { collapseSidebar } = useProSidebar();
  const { shoppingCart, qrcode } = props;

  return (
    <div className="footer-navigation">
      <div className="navigation-item" onClick={() => collapseSidebar()}>
        <div className="navigation-item-cart">
          <img src="/assets/icon/cart-shopping-solid.svg" alt="" />
          <p className="total-items">{shoppingCart?.total_quantity || 0}</p>
        </div>
      </div>
      <Link to={'/m/' + qrcode?.uuid} className="navigation-item">
        <img src="/assets/icon/house-chimney-solid.svg" alt="" />
      </Link>
      <div className="navigation-item">
        <LocalSwitchModal position="footer" />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    shoppingCart: state.shoppingCart,
    qrcode: state.qrcode,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchShoppingCartDetails: fetchShoppingCartDetails,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterNavigation);
