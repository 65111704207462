import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import UtilityService from '../services/utility';

function CategoryImage(props) {
  const [loaded, setLoaded] = useState(false);

  return (
    <div className="category-image">
      {!loaded && <Skeleton borderRadius={20} height={140} />}
      <img
        alt={props.title}
        onLoad={() => setLoaded(true)}
        src={UtilityService.getCategoryImageURL(props.category)}
        style={{ display: loaded ? 'block' : 'none' }}
      />
    </div>
  );
}

export default CategoryImage;
