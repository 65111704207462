import React from 'react';
import DefaultLayout from '../layout/DefaultLayout';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';

function OrderConfirmationContainer(props) {
  const { t } = useTranslation();

  return (
    <>
      <DefaultLayout className="template-v1">
        <div className="section order-confirmation-container">
          <img alt="" className="order-confirmation-icon" src="/assets/icon/confirmation.svg" />
          <div className="order-confirmation-details">
            <p className="order-successfully">{t('Your Order Is Confirmed')}</p>
            <p className="thank-you">{t('Thank you!')}</p>
            <p className="tag-message">{t('ORDER_HINT')}</p>
            <div className="back-link">
              <Link to="../menu">{t('BACK TO OUR MENU')}</Link>
            </div>
          </div>
        </div>
      </DefaultLayout>
    </>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    shoppingCart: state.shoppingCart,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmationContainer);
