import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from './components/Layout';
import Group from './components/Group';
import useCategories from './hooks/useCategories';

const ProductListPage = ({ menu }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeCategoryId, setActiveCategoryId] = useState(id);

  const categories = useCategories(menu);
  const groups = useCategories(menu, id);

  const handleNavigateToCategory = (categoryId) => {
    navigate(`/m/lb7vz-hkia7-uoj57-eqhsx-uncjy/category/${categoryId}`);
    setActiveCategoryId(categoryId);
  };

    const productListStyles = {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '20px',
    minHeight: '100vh',
    borderBottomLeftRadius: '25px',
    borderBottomRightRadius: '25px',
  };

  const getButtonStyles = (categoryId) => ({
    borderRadius: '50px',
    border: categoryId === activeCategoryId ? '1px solid white' : '1px solid #ef282b',
    color: categoryId === activeCategoryId ? 'white' : '#ef282b',
    backgroundColor: categoryId === activeCategoryId ? '#ef282b' : 'white',
    fontSize: '16px',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 8,
    padding: '10px 20px',
    margin: '0 5px'
  });

  const buttonContainer = {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 60,
    gap: 10
  };

  return (
    <Layout>
      <div style={productListStyles}>
        <div style={buttonContainer}>
          {categories.map(category => (
            <button
              key={category.id}
              style={getButtonStyles(category.id)}
              onClick={() => handleNavigateToCategory(category.id)}
            >
              {category.name}
            </button>
          ))}
        </div>
        {groups.map(group => (
          <Group key={group.id} group={group} menu={menu} />
        ))}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  menu: state.menu,
});

export default connect(mapStateToProps)(ProductListPage);
