import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { setConfig } from '../../../actions/configActions';
import { setMenu, setProductsMap } from '../../../actions/menuActions';
import { fetchQRCode } from '../../../actions/qrcodeActions';
import { fetchShoppingCartDetails } from '../../../actions/shoppingCartActions';
import MenuService from '../../../services/menu';
import UtilityService from '../../../services/utility';
import { SUPPORTED_LAYOUT, SUPPORTED_SORT } from '../../../utils/constant';
import { getCurrency, getCurrentCurrency, getCurrentLanguage, getLanguage } from '../../../utils/utility';
import Footer from '../../../components/Footer';
import Header from './Header';
import useCart from '../../../hooks/useCart';

function Layout(props) {
  const uuid = 'lb7vz-hkia7-uoj57-eqhsx-uncjy';
  const { i18n } = useTranslation();
  const { menu, config } = props;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const source = searchParams.get('source');
  let htmlStyle = '';

  if (source === 'preview') {
    htmlStyle = 'zoom: 0.8';
  }

  const { diningOption } = useCart();

  useEffect(() => {
    if ((props.qrcode == null || props.qrcode.id == null) && uuid != null) {
      props.fetchQRCode(uuid).then((data) => {
        if (data != null && data.menu != null) {
          // 1. resolve language from local storage.
          let fetchLang = getCurrentLanguage(data.menu.id);
          if (fetchLang === null) {
            // if no language in local storage, use ,menu defualt language
            fetchLang = getLanguage(parseInt(data.menu.default_lang));
          }

          // save current language in reducer
          props.setConfig({ currentLanguage: fetchLang });

          // change language in i18n library
          i18n.changeLanguage(fetchLang.code);

          // 2. resolve currnecy from local storage.
          let fetchCurrency = getCurrentCurrency(data.menu.id);
          if (fetchCurrency === null) {
            // if no currency in local storage, use ,menu defualt currency
            fetchCurrency = getCurrency(parseInt(data.menu.default_currency));
          }

          // save current currency in reducer
          props.setConfig({ currentCurrency: fetchCurrency });

          const defaultLayout = data.menu?.settings.DEFAULT_LAYOUT || null;
          const defaultSort = data.menu?.settings.DEFAULT_SORT || null;

          // set default layout & sort
          if (SUPPORTED_LAYOUT.indexOf(defaultLayout) !== -1) {
            props.setConfig({ productLayout: defaultLayout });
          } else {
            props.setConfig({ productLayout: SUPPORTED_LAYOUT[0] });
          }

          if (defaultSort) {
            props.setConfig({ productSort: defaultSort });
          } else {
            props.setConfig({ productSort: SUPPORTED_SORT[0].value });
          }

          MenuService.getMenuJSON(data.menu.id, fetchLang.id, data.menu.version)
            .then((res) => res.json())
            .then((menuData) => {
              if (menuData) {
                // set default selected category
                if (menuData.categories != null && menuData.categories.length > 0) {
                  menuData.selectedCategory = menuData.categories[0].id;
                }

                // save menu in reducer
                props.setMenu(menuData);

                // build product map
                // TODO: NO LONGER NEED THIS CODE
                props.setProductsMap(UtilityService.buildProductMap(menuData));
              }
            });

          // get diningOption from session
          if (diningOption === 'DINE_IN' || diningOption === 'TAKE_AWAY') {
            props.setConfig({ dineInOrTakeaway: diningOption });
          }

          // fetch shopping cart data
          props.fetchShoppingCartDetails(data.id);

          props.setConfig({ dineInOrTakeaway: diningOption });

          MenuService.sendTrackEvent('MENU_VIEW', { menuId: data.menu.id });
        } else {
          // invalid uuid
          navigate('/not-found');
        }
      });
    }
  }, [navigate, uuid, props, i18n]);

  const dir = config.currentLanguage && config.currentLanguage.dir;

  return (
    <>
      <Header showBusinessDetails="true" />
      <main>{props.children}</main>
      <Footer name="GetMenu" website="www.getmenu.ps" />
    </>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    qrcode: state.qrcode,
    config: state.config,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchQRCode: fetchQRCode,
      fetchShoppingCartDetails: fetchShoppingCartDetails,
      setConfig: setConfig,
      setMenu: setMenu,
      setProductsMap: setProductsMap,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
