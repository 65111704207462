import { KEYS } from '../utils/constant';
import { useSelector, useDispatch } from 'react-redux';

function useCart() {
  // Access the Redux state
  const menu = useSelector((state) => state.menu);
  const qrcode = useSelector((state) => state.qrcode);
  const shoppingCart = useSelector((state) => state.shoppingCart);

  const allowToOrder = resolveAllowToOrder(qrcode, menu);
  const miniCartButtonEanbled = menu?.settings?.MINI_CART_BUTTON_ENABLED === 'true';

  const diningOption = sessionStorage.getItem(KEYS.DINING_OPTION);

  return { allowToOrder, miniCartButtonEanbled, shoppingCart, diningOption };
}

export default useCart;

function resolveAllowToOrder(qrcode, menu) {
  /*
    Allow to order is calculated base on:
        1. Default value is false
        2. Menu Settings
        3. QR Code Settings
        3. Session Storage
  */
  let allowToOrder = false;
  if (menu?.settings?.ALLOW_TO_ORDER === 'true') {
    allowToOrder = true;
  }

  if (qrcode?.settings?.ALLOW_TO_ORDER === 'true') {
    allowToOrder = true;
  } else if (qrcode?.settings?.ALLOW_TO_ORDER === 'false') {
    allowToOrder = false;
  }

  if (sessionStorage.getItem(KEYS.ALLOW_TO_ORDER) === 'true') {
    allowToOrder = true;
  } else if (sessionStorage.getItem(KEYS.ALLOW_TO_ORDER) === 'false') {
    allowToOrder = false;
  }

  return allowToOrder;
}
