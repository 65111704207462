import React from 'react';
import ReactDOM from 'react-dom';
import './style.css';
import App from './App';
import thunk from 'redux-thunk';
import reducer from './reducers';
import { configureStore } from '@reduxjs/toolkit';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { CookiesProvider } from 'react-cookie';
import './i18n';
import * as serviceWorker from './serviceWorker';

const store = configureStore({ reducer }, thunk);

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <Provider store={store}>
        <ProSidebarProvider>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </ProSidebarProvider>
      </Provider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.register();

const path = window.location.pathname;
const uuidRegex = /.{5}-.{5}-.{5}-.{5}-.{5}/gm;

if (path.split('/').length > 2) {
  const uuid = path.split('/')[2];
  if (uuidRegex.test(uuid)) {
    document.querySelector('#manifestLink').setAttribute('href', process.env.REACT_APP_API_URL + '/manifest/' + uuid);
  }
}
