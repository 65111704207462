import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProSidebar } from 'react-pro-sidebar';
import { connect } from 'react-redux';
import { fetchShoppingCartDetails } from '../actions/shoppingCartActions';
import ShoppingCartService from '../services/shoppingCart';
import TableSelectionModal from './TableSelectionModal';
import useCart from '../hooks/useCart';

function AddToCart(props) {
  const { allowToOrder, shoppingCart, diningOption } = useCart();

  const { productId, config, merchandisingAssociations, menu } = props;
  const [isModalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();
  const currency = config.currentCurrency;
  const [text, setText] = useState(t('Add To Cart'));
  const [comments, setComments] = useState(t(''));
  const { collapseSidebar } = useProSidebar();

  const autoCartOpen = menu?.settings?.AUTO_CART_OPEN === 'true';
  const showTableSelectionModalOnFirstItem = menu?.settings?.SHOW_TABLE_SELECTION_MODAL_ON_FIRST_ITEM === 'true';

  const selectedTable = shoppingCart?.tableNumber || shoppingCart?.table_number;

  const addProductToCart = () => {
    if (showTableSelectionModalOnFirstItem && (!selectedTable || parseInt(selectedTable) < 1)) {
      setModalVisible(true);
    } else {
      setText(t('Add To Cart ...'));
      sendAddToCartRequest();
    }
  };

  const sendAddToCartRequest = () => {
    ShoppingCartService.addProductToCart(
      props.qrcode.id,
      productId,
      1,
      currency.id,
      diningOption,
      merchandisingAssociations,
      comments,
      selectedTable
    )
      .then((response) => response.json())
      .then((data) => {
        props.fetchShoppingCartDetails(props.qrcode.id).then(() => {
          setText(t('Add To Cart'));
          if (autoCartOpen) {
            collapseSidebar();
          }
        });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  if (!allowToOrder) {
    return null;
  }

  return (
    <>
      {props.showNote && props.menu?.settings?.ADD_ITEM_NOTE_ENABLED === 'true' && (
        <div className="item-comments">
          <p className="item-comments-title">{t('Notes')}:</p>
          <textarea
            rows="3"
            placeholder={t('Add Your Comments...')}
            value={comments}
            onChange={(event) => setComments(event.target.value)}></textarea>
        </div>
      )}
      {showTableSelectionModalOnFirstItem && <TableSelectionModal isOpen={isModalVisible} onHideModal={() => setModalVisible(false)} />}
      <button className="btn-add-to-cart" disabled={!productId} onClick={addProductToCart}>
        {t(text)}
      </button>
    </>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    qrcode: state.qrcode,
    config: state.config,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchShoppingCartDetails: fetchShoppingCartDetails,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCart);
