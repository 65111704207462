import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function SelectOptions(props) {
  const { productId } = props;
  const { t } = useTranslation();

  return (
    <Link className="btn-select-options" to={'product/' + productId}>
      {t('Select Options ...')}
    </Link>
  );
}

export default SelectOptions;
