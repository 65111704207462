import React, { Component } from 'react';
import { connect } from 'react-redux';
import BusinessCard from '../../components/BusinessCard';
import DineInTakeawaySwitchModal from '../../components/DineInTakeawaySwitchModal';
import FooterNavigation from '../../components/FooterNavigation';
import SpecialsOffer from '../../components/SpecialsOffer';
import MainCategories from '../MainCategories';

class HomePageV1 extends Component {
  render() {
    const { menu } = this.props;
    return (
      <>
        <BusinessCard />

        <MainCategories />

        {menu?.special_offers?.length > 0 && (
          <>
            <div className="divider"></div>
            <SpecialsOffer />
          </>
        )}

        {menu.settings.DINE_IN_TAKEAWAY_MODAL_ENABLED === 'true' && <DineInTakeawaySwitchModal />}
        {menu?.settings?.FOOTER_NAVIGATION_ENABLED === 'true' && <FooterNavigation />}
      </>
    );
  }
}

// mapStateToProps maps the state from the Redux store to props of the component
const mapStateToProps = (state) => {
  return {
    menu: state.menu,
    config: state.config,
  };
};

// mapDispatchToProps maps the dispatch of Redux actions to props of the component
const mapDispatchToProps = (dispatch) => {
  return {};
};

// connect the component with the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(HomePageV1);
