import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import UtilityService from '../services/utility';
import ProductPrice from './ProductPrice';

function MerchandisingAssociations(props) {
  const { t } = useTranslation();
  const { menu } = props;
  const product = props.product;
  const currency = props.config.currentCurrency;
  const merchandisingAssociations = product.merchandisingAssociations;
  const additions = merchandisingAssociations.filter((a) => a.type === 'ADDITIONS');
  const sauce = merchandisingAssociations.filter((a) => a.type === 'SAUCE');
  const ingredients = merchandisingAssociations.filter((a) => a.type === 'INGREDIENTS');
  const options = merchandisingAssociations.filter((a) => a.type === 'WITHOUT');

  const allowToOrder = menu?.settings?.ALLOW_TO_ORDER === 'true';
  const hideZeroPriceForIngredients = menu?.settings?.HIDE_ZERO_PRICE_FOR_INGREDIENTS === 'true';

  const [selectedAssociations, setSelectedAssociations] = useState(
    merchandisingAssociations
      .map((a) => {
        if (parseInt(a.defaultSelected) === 1) {
          return a.productId;
        }
        return null;
      })
      .filter((a) => a != null)
  );

  const handleCheckClick = (productId) => {
    const temp = selectedAssociations;
    if (temp.indexOf(productId) !== -1) {
      temp.splice(temp.indexOf(productId), 1);
    } else {
      temp.push(productId);
    }
    setSelectedAssociations([...temp]);
    props.onChange(temp);
  };

  const isSelected = (productId) => {
    return selectedAssociations.indexOf(productId) !== -1;
  };

  return (
    <>
      <div className="merchandising-associations">
        {additions && additions.length > 0 && (
          <div className="merchandising-associations-additions">
            <p className="merchandising-associations-title">{t('Additions')}:</p>
            <div className="merchandising-associations-list">
              {additions.map((item, index) => {
                const product = menu.products.find((p) => parseInt(p.id) === parseInt(item.productId));
                return (
                  <div key={index} className="merchandising-associations-item">
                    <img src={UtilityService.getProductImageURL(product)} alt="" />
                    <p className="name">{product?.name}</p>
                    <div className="price">
                      <ProductPrice product={product} />
                    </div>
                    {allowToOrder && (
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={isSelected(item.productId)}
                        onChange={() => handleCheckClick(item.productId)}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {options && options.length > 0 && (
          <div className="merchandising-associations-additions">
            <p className="merchandising-associations-title">{t('Options')}:</p>
            <div className="merchandising-associations-list">
              {options.map((item, index) => {
                const product = menu.products.find((p) => parseInt(p.id) === parseInt(item.productId));
                return (
                  <div key={index} className="merchandising-associations-item">
                    <img src={UtilityService.getProductImageURL(product)} alt="" />
                    <p className="name">
                      {item.type === 'WITHOUT' && product.partnumber !== 'FX153' ? t('without') + ' ' : ''}
                      {product?.name}
                    </p>
                    <div className="price"></div>
                    {allowToOrder && (
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={isSelected(item.productId)}
                        onChange={() => handleCheckClick(item.productId)}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {sauce && sauce.length > 0 && (
          <div className="merchandising-associations-additions">
            <p className="merchandising-associations-title">{t('Sauce For Choice')}:</p>
            <div className="merchandising-associations-list">
              {sauce.map((item, index) => {
                const product = menu.products.find((p) => parseInt(p.id) === parseInt(item.productId));
                return (
                  <div key={index} className="merchandising-associations-item">
                    <img src={UtilityService.getProductImageURL(product)} alt="" />
                    <p className="name">{product?.name}</p>
                    <div className="price">
                      <ProductPrice product={product} />
                    </div>
                    {allowToOrder && (
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={isSelected(item.productId)}
                        onChange={() => handleCheckClick(item.productId)}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {ingredients && ingredients.length > 0 && (
          <div className="merchandising-associations-ingredients">
            <p className="merchandising-associations-title">{t('Ingredients')}:</p>
            <div className="merchandising-associations-list">
              {ingredients.map((item, index) => {
                const product = menu.products.find((p) => parseInt(p.id) === parseInt(item.productId));
                const price = product['price_' + currency.id] || 0;
                return (
                  <div key={index} className="merchandising-associations-item">
                    <img src={UtilityService.getProductImageURL(product)} alt="" />
                    <p className="name">{product?.name}</p>
                    {(price != 0 || !hideZeroPriceForIngredients) && (
                      <div className="price">
                        <ProductPrice product={product} />
                      </div>
                    )}
                    {allowToOrder && (
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={isSelected(item.productId)}
                        onChange={() => handleCheckClick(item.productId)}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    config: state.config,
  };
}

export default connect(mapStateToProps)(MerchandisingAssociations);
