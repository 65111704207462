import React from 'react';

const Items = ({ item, index, showThumbnail }) => {
  const menuContainerStyles = {
    padding: '5px 15px 8px 15px',
    color: 'black',
    fontSize: '18px',
  };

  const itemStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    ...(index === 0 && { borderTop: '2px solid black', paddingTop: '10px' }),
    fontSize: 13,
    fontWeight: 600
  };

  const itemThumbnail= {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
  };

  return (
    <div style={menuContainerStyles}>
      {index === 0 && (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', fontWeight: 'bold' }}>
          <span>Item</span>
          <span>Price</span>
        </div>
      )}
      <div style={itemStyles}>
        <div style={itemThumbnail}>
       {showThumbnail && <img src={item?.imageUrl} width={35} />}
        <span>{item?.name}</span>
        </div>
        <span>{item?.price}</span>
      </div>
    </div>
  );
};

export default Items;
