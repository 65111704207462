import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useProSidebar } from 'react-pro-sidebar';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadShoppingCart } from '../actions/shoppingCartActions';
import FormatPrice from '../components/FormatPrice';
import Header from '../components/activeOrders/Header';
import DefaultLayout from '../layout/DefaultLayout';
import ShoppingCartService from '../services/shoppingCart';
import PrintOrderView from '../components/PrintOrderView';

function ActiveOrdersContainer(props) {
  const { t, qrcode } = props;
  const navigate = useNavigate();
  const { collapseSidebar } = useProSidebar();
  const [orderList, setOrderList] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    if (qrcode && qrcode.id && orderList.length === 0) {
      fetchList();
    }
  }, [qrcode]);

  const fetchList = () => {
    ShoppingCartService.fetchShoppingCartList(qrcode.id)
      .then((response) => response.json())
      .then((data) => {
        setOrderList(data);
      });
  };

  const loadCart = (orderId) => {
    props.loadShoppingCart(qrcode.id, orderId).then((success) => {
      if (success) {
        navigate('../menu');
        setTimeout(() => {
          collapseSidebar();
        }, 1000);
      }
    });
  };

  const completeOrder = (orderId) => {
    ShoppingCartService.completeOrder(qrcode.id, orderId).then(() => {
      fetchList();
    });
  };

  return (
    <>
      <DefaultLayout className="template-v1">
        <div className="section active-orders-container">
          <Header />
          <div className="active-orders-list">
            {orderList.map((order, index) => (
              <div className="active-orders-list-item" key={index}>
                <div className="order-details">
                  <p className="order-number">
                    {t('Order#')}: {order.id}
                  </p>
                  {order.table_number && (
                    <p className="table-number">
                      {t('Table Number')}: {order.table_number}
                    </p>
                  )}
                  <p className="total-amount">
                    <FormatPrice value={order.amount || 0} />
                  </p>
                </div>
                <div className="order-actions">
                  <div>
                    <button onClick={() => loadCart(order.id)}>
                      <img src="/assets/icon/pen-to-square-solid.svg" />
                    </button>
                  </div>
                  <div>
                    <PrintOrderView order={order} buttonIcon="/assets/icon/print-solid.svg" />
                  </div>
                  <div>
                    <button onClick={() => completeOrder(order.id)}>
                      <img src="/assets/icon/circle-check-regular-green.svg" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </DefaultLayout>
    </>
  );
}

function mapStateToProps(state) {
  return {
    qrcode: state.qrcode,
    menu: state.menu,
    shoppingCart: state.shoppingCart,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadShoppingCart: loadShoppingCart,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ActiveOrdersContainer));
