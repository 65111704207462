import ShoppingCartService from '../services/shoppingCart';

export function fetchShoppingCartSummary(qrcodeId) {
  return async (dispatch) => {
    const response = await ShoppingCartService.fetchShoppingCartSummary(qrcodeId);
    const data = await response.json();
    dispatch(setShoppingCart(data));
    return data;
  };
}

export function fetchShoppingCartDetails(qrcodeId) {
  return async (dispatch) => {
    const response = await ShoppingCartService.fetchShoppingCartDetails(qrcodeId);
    const data = await response.json();
    dispatch(setShoppingCart(data));
    return data;
  };
}

export function loadShoppingCart(qrcodeId, orderId) {
  return async (dispatch) => {
    const response = await ShoppingCartService.loadShoppingCart(qrcodeId, orderId);
    const data = await response.json();
    if (data.success) {
      dispatch(fetchShoppingCartDetails(qrcodeId));
      return true;
    } else {
      alert('Error');
      return false;
    }
  };
}

export function updateShoppingCart(qrcodeId, data) {
  return async (dispatch) => {
    await ShoppingCartService.updateShoppingCart(qrcodeId, data);
    dispatch(fetchShoppingCartDetails(qrcodeId));
  };
}

export function setShoppingCartComment(comments) {
  return async (dispatch) => {
    dispatch({ type: 'SET_SHOPPING_CART_COMMENTS', payload: comments });
  };
}

export function setShoppingCartCustomerName(name) {
  return async (dispatch) => {
    dispatch({ type: 'SET_SHOPPING_CART_NAME', payload: name });
  };
}

export function setShoppingCartTableNumber(tableNumber) {
  return async (dispatch) => {
    dispatch({ type: 'SET_SHOPPING_CART_TABLE_NUMBER', payload: tableNumber });
  };
}

export function setShoppingCartCustomerMobile(mobile) {
  return async (dispatch) => {
    dispatch({ type: 'SET_SHOPPING_CART_MOBILE', payload: mobile });
  };
}

export function resetShoppingCartCart() {
  return async (dispatch) => {
    dispatch(setShoppingCart(null));
    dispatch(setShoppingCartTableNumber(null));
  };
}

function setShoppingCart(data) {
  return { type: 'SET_SHOPPING_CART', payload: data };
}
