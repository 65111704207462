import React from "react";

function Footer({ name, website }) {
  return (
    <div className="footer">
      <p>Powered by © 2024 {name}</p>
      <a target="_blank" rel="noopener noreferrer" href={"https://" + website}>
        {website}
      </a>
    </div>
  );
}

export default Footer;
