import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetShoppingCartCart } from '../../actions/shoppingCartActions';
import ShoppingCartService from '../../services/shoppingCart';
import PrintOrderView from '../PrintOrderView';

function OrderActions(props) {
  const { t, qrcode, shoppingCart, menu } = props;
  const navigate = useNavigate();

  const customerFormEnabled = menu?.settings?.SHOW_CUSTOMER_FORM_IN_CHECKOUT === 'true';
  const tableSelectionEnabled = menu?.settings?.SHOW_TABLE_SELECTOR_IN_CHECKOUT === 'true';

  const printCartEnabled = menu?.settings?.SHOW_PRINT_CART_BUTTON_IN_CHECKOUT === 'true' || true;

  const submitOrder = () => {
    if (customerFormEnabled && (!shoppingCart || !shoppingCart.customerName || !shoppingCart.customerMobile)) {
      alert(t('Please fill your name and mobile'));
      return;
    }

    const tableNumber = shoppingCart?.tableNumber || shoppingCart?.table_number;
    if (tableSelectionEnabled && (!tableNumber || parseInt(tableNumber) <= 0)) {
      alert(t('Please select table number'));
      return;
    }

    const data = {
      comments: shoppingCart?.comments,
      customerName: shoppingCart?.customerName,
      customerMobile: shoppingCart?.customerMobile,
    };

    if (customerFormEnabled) {
      data.customerName = shoppingCart?.customerName;
      data.customerMobile = shoppingCart?.customerMobile;
    }

    if (tableSelectionEnabled) {
      data.tableNumber = tableNumber;
    }

    ShoppingCartService.submitCart(qrcode.id, data)
      .then((response) => {
        if (response.ok) {
          props.resetShoppingCartCart();
          navigate('../confirmation');
        } else {
          alert('Unable to submit your order right now, please try again later!');
        }
      })
      .catch((error) => {
        alert('Unable to submit your order right now, please try again later!');
      });
  };

  let extraClass = '';
  if (printCartEnabled) {
    extraClass = 'print-enabled';
  }

  return (
    <div className={'order-actions ' + extraClass}>
      <button className="btn-submit-order" onClick={submitOrder}>
        {t('Submit Your Order')}
      </button>
      {printCartEnabled && (
        <PrintOrderView buttonClassName="btn-print-order" buttonIcon="/assets/icon/print-solid-white.svg" order={shoppingCart} />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    qrcode: state.qrcode,
    shoppingCart: state.shoppingCart,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ resetShoppingCartCart: resetShoppingCartCart }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderActions));
