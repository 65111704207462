import { useMemo } from 'react';

const useProductInfoByGroupId = (itemIds = [], menu = { products: [] }) => {
  return useMemo(() => {
    if ((!menu.products)) {
      return [];
    }
    const productMap = new Map(menu.products.map(product => [product.id, product]));

    return itemIds.map(itemId => {
      const product = productMap.get(itemId);
      if (!product) {
        return null
      }
      const imageUrl = product.media?.find(media => media.is_default === '1')?.path || '/assets/icon/default-product-image.svg';
      return {
        id: product.id,
        name: product.name,
        price: product.price_2,
        imageUrl,
      };
    });
  }, [itemIds, menu.products]);
};

export default useProductInfoByGroupId;
