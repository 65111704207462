import * as urls from '../utils/urls';

const ShoppingCartService = {
  fetchShoppingCartList: function (qrcodeId) {
    return fetch(urls.GET_SHOPPING_CART_LIST_URL.replace('{qrcodeId}', qrcodeId), { credentials: 'include', mode: 'cors' });
  },

  fetchShoppingCartSummary: function (qrcodeId) {
    return fetch(urls.GET_SHOPPING_CART_SUMMARY_URL.replace('{qrcodeId}', qrcodeId), { credentials: 'include', mode: 'cors' });
  },

  fetchShoppingCartDetails: function (qrcodeId) {
    return fetch(urls.GET_SHOPPING_CART_DETAILS_URL.replace('{qrcodeId}', qrcodeId), { credentials: 'include' });
  },

  loadShoppingCart: function (qrcodeId, orderId) {
    return fetch(urls.LOAD_SHOPPING_CART_URL.replace('{qrcodeId}', qrcodeId), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ orderId: orderId }),
    });
  },

  addProductToCart: function (qrcodeId, catentryId, quantity, currencyId, orderType, merchandisingAssociations, comments, selectedTable) {
    const data = {
      catentryId: catentryId,
      quantity: quantity,
      currencyId: currencyId,
      type: orderType,
      comments: comments,
      tableNumber: selectedTable,
    };

    if (merchandisingAssociations && merchandisingAssociations.length > 0) {
      data.merchandisingAssociations = merchandisingAssociations;
    }

    return fetch(urls.ADD_PRODUCT_TO_CART_URL.replace('{qrcodeId}', qrcodeId), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  updateShoppingCart: function (qrcodeId, data) {
    return fetch(urls.UPDATE_SHOPPING_CART_URL.replace('{qrcodeId}', qrcodeId), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  removeItemFromCart: function (qrcodeId, itemId) {
    return fetch(urls.REMOVE_ITEM_FROM_CART_URL.replace('{qrcodeId}', qrcodeId).replace('{itemId}', itemId), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
  updateItemQuantity: function (qrcodeId, itemId, quantity) {
    const data = { itemId: itemId, quantity: quantity };

    return fetch(urls.UPDATE_CART_ITEM_URL.replace('{qrcodeId}', qrcodeId), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  submitCart: function (qrcodeId, data) {
    return fetch(urls.SUBMIT_CART_URL.replace('{qrcodeId}', qrcodeId), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  completeOrder: function (qrcodeId, orderId) {
    return fetch(urls.UPDATE_ORDER_URL.replace('{qrcodeId}', qrcodeId), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ orderId: orderId, status: 2 }),
    });
  },
};

export default ShoppingCartService;
