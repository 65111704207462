import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setShoppingCartTableNumber } from '../../actions/shoppingCartActions';

function TableSelection(props) {
  const { t, shoppingCart, menu } = props;

  const tableCount = parseInt(menu?.settings.TABLES_COUNT || 0);
  const selectedTable = shoppingCart?.tableNumber || shoppingCart?.table_number;

  return (
    <div className="order-table-select">
      <p className="order-table-select-title">{t('Table Number')}:</p>
      <div className="table-list">
        {[...Array(tableCount)].map((item, index) => (
          <div
            key={index}
            className={'table ' + (index + 1 == selectedTable ? 'active' : '')}
            onClick={() => props.setShoppingCartTableNumber(index + 1)}>
            <span>{index + 1}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    shoppingCart: state.shoppingCart,
    menu: state.menu,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setShoppingCartTableNumber: setShoppingCartTableNumber,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TableSelection));
