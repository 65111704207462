import React from 'react';

const ItemDisplay = ({ item }) => {
  const containerStyles = {
    textAlign: 'center',
    padding: '10px',
  };

  const imageStyles = {
    width: 35,
  };

  const itemsStyle = {
    fontSize: 10,
    fontWeight: 600
  };

  

  return (
    <div style={containerStyles}>
      <img src={item.imageUrl} alt={item.name} style={imageStyles} />
      <div style={itemsStyle}>{item.name}</div>
    </div>
  );
};

export default ItemDisplay;
