import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import StarRatings from 'react-star-ratings';
import { fetchProductReviews } from '../actions/reviewsActions';
import WriteReviewModal from './WriteReviewModal';

function ProductReviews(props) {
  const { reviews, product } = props;
  const { t } = useTranslation();

  useEffect(() => {
    props.fetchProductReviews(product.id);
  }, [props, product.id]);

  return (
    <div className="product-reviews">
      <WriteReviewModal productId={product?.id} />

      <div className="product-customer-reviews-list">
        <p className="product-customer-reviews-list-title">{t('Customer Reviews')}:</p>
        {reviews &&
          reviews.map((review) => {
            return (
              <div key={review.id} className="product-customer-reviews-list-item">
                <div className="review-rating">
                  <StarRatings
                    rating={parseFloat(review.rate)}
                    starDimension="15px"
                    starSpacing="5px"
                    starRatedColor="#5777ba"
                    numberOfStars={5}
                    name="rating"
                  />
                </div>
                <p className="review-text">{review.text}</p>
                <p className="review-submited-details">
                  Submitted on {review.created_at.substring(0, 10)} by {review.name}
                </p>
              </div>
            );
          })}
        {(reviews === null || reviews.length === 0) && (
          <p className="no-review-message">{t('There are no any reviews for this item yet!')}</p>
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    reviews: state.reviews,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchProductReviews: fetchProductReviews }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductReviews);
