import React from 'react';

function Text(props) {
  return (
    <div className={'category ' + (props.active ? 'active' : '')} onClick={props.onClick}>
      <span>{props.name}</span>
    </div>
  );
}

export default Text;
