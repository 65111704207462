import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setShoppingCartComment } from '../../actions/shoppingCartActions';

function OrderComments(props) {
  const { t, shoppingCart } = props;

  const handleCommentChanged = (event) => {
    props.setShoppingCartComment(event.target.value);
  };

  return (
    <div className="order-comments">
      <p className="order-comments-title">{t('Notes')}:</p>
      <textarea rows="5" placeholder={t('Add Your Comments...')} value={shoppingCart?.comments} onChange={handleCommentChanged}></textarea>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    shoppingCart: state.shoppingCart,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setShoppingCartComment: setShoppingCartComment }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderComments));
