const config = {
  productLayout: 'small-grid',
  currentLanguage: null,
  currentCurrency: null,
  productSort: 'sequence',
};

const configReducer = (state = config, action) => {
  switch (action.type) {
    case 'SET_CONFIG':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default configReducer;
