import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Header from './Header';
import MenuSocialLink from './MenuSocialLink';

function BusinessCard(props) {
  const { menu } = props;

  let showLocalSwitch = false;

  if (menu.supported_lang.split(',').length > 1) {
    showLocalSwitch = true;
  }

  if (menu.supported_currency.split(',').length > 1) {
    showLocalSwitch = true;
  }

  return (
    <div className="section business-section">
      {menu && (
        <>
          <Header showBackButton={false} showShareButton={true} showLocalSwitch={showLocalSwitch} showBusinessDetails={true} />
          {menu?.settings?.SOCIAL_LINK_ENABLED === 'true' && <MenuSocialLink />}
          <div className="business-details">
            <div className="business-details-text">
              <h1>{menu.name}</h1>
              {menu.description && <p className="paragraph-large">{menu.description}</p>}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BusinessCard));
