const defaultMenuObj = {
  selectedCategory: null,
  productsMap: null,
};

const menu = {};

const menuReducer = (state = menu, action) => {
  switch (action.type) {
    case 'SET_MENU':
      return action.payload;
    case 'SET_SELECTED_CATEGORY':
      return { ...state, selectedCategory: action.payload };
    case 'SET_PRODUCTS_MAP':
      return { ...state, productsMap: action.payload };
    case 'REST_MENU':
      return defaultMenuObj;
    default:
      return state;
  }
};

export default menuReducer;
