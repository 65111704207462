import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CategoryImage from './../../components/CategoryImage';

const fullWidthCategoryIndex = [0, 1, 4];
const coldDrinksIndex = [5, 6, 7, 8, 9, 10, 11];

function CategoryListV1(props) {
  const { mainCategories, onCategorySelected } = props;
  const [showColdDrink, setShowColdDrink] = useState(false);

  return (
    <div className="category-list category-list-v1">
      {mainCategories &&
        mainCategories.map((category, index) => {
          return (
            <>
              {index === 5 && (
                <div className="category full-width" key={category.id + '_seperater'} onClick={() => setShowColdDrink(!showColdDrink)}>
                  <div className="category-details">
                    <div class="category-image">
                      <img alt="MOCHI" src="https://images.getmenu.ps/accounts/6/category/COLD_DRINKS.jpg" />
                    </div>
                  </div>
                </div>
              )}

              {index === 12 && <hr className="category horizantal-line full-width" key={category.id + '_seperater'} />}

              {index === 14 && (
                <div className="category full-width" key={category.id + '_seperater'}>
                  <div className="category-details">
                    <h2 className="title">
                      <span style={{ borderBottom: '2px solid red' }}>HOT DRINKS</span>
                    </h2>
                  </div>
                </div>
              )}
              {(coldDrinksIndex.indexOf(index) === -1 || showColdDrink) && (
                <div
                  role="listitem"
                  category-index={index}
                  category-id={category.id}
                  key={category.id}
                  className={'category' + (fullWidthCategoryIndex.indexOf(index) !== -1 ? ' full-width' : '')}
                  onClick={() => onCategorySelected(category.id)}>
                  <Link to="menu">
                    <CategoryImage category={category} title={category.name} />
                    <div className="category-details">
                      <h4 className="title">{category.name}</h4>
                      {category.description && <p className="description">{category.description}</p>}
                    </div>
                  </Link>
                </div>
              )}
            </>
          );
        })}
    </div>
  );
}

export default CategoryListV1;
