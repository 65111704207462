import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { MEDIA_BASE_URL } from '../utils/constant';
import LocalSwitchModal from './LocalSwitchModal';
import ShareModal from './ShareModal';
import { useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

function Header(props) {
  const { menu, config, t } = props;
  const { uuid } = useParams();

  const getMenuLogo = () => {
    let logo = '/assets/icon/default-menu-logo.svg';
    if (menu.logo) {
      logo = menu.logo;
      if (logo && !logo.startsWith('http')) {
        logo = MEDIA_BASE_URL + logo;
      }
    }
    return logo;
  };

  const getMenuBackground = () => {
    let image = '/assets/icon/default-menu-background.png';
    if (menu.background) {
      image = menu.background;
      if (image && !image.startsWith('http')) {
        image = MEDIA_BASE_URL + image;
      }
    }
    return image;
  };

  const activeOrdersUrl = '/m/' + uuid + '/orders';

  return (
    <div className="top-header">
      <div className="top-actions">
        <div className="left">
          {props.showBackButton && (
            <div className="action back">
              <Link to={props.backURL}>
                <img src="/assets/icon/primary-arrow.svg" alt="" />
              </Link>
            </div>
          )}
          {props.showShareButton && menu?.settings.SHARE_LINK_ENABLED === 'true' && (
            <div className="action share">
              <ShareModal />
            </div>
          )}
        </div>
        <div className="right">
          {menu?.settings?.ACTIVE_ORDERS_ENABLED === 'true' && (
            <Link to={activeOrdersUrl} className="active-orders-button">
              {t('Orders')}
              <img src="/assets/icon/cart-shopping-solid.svg" alt="" />
            </Link>
          )}
          {props.showLocalSwitch && config && <LocalSwitchModal />}
        </div>
      </div>
      {props.showBusinessDetails && (
        <>
          <div className="image-wrapper">
            <img alt="" src={getMenuBackground()} className="image" />
          </div>
          <div className="container">
            <div className="business-image">
              <img alt="" src={getMenuLogo()} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    config: state.config,
    qrcode: state.qrcode,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Header));
