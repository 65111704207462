import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { connect } from 'react-redux';
import ProductCardV1 from './ProductCardV1';
import ProductCardV2 from './ProductCardV2';

function ProductCard(props) {
  const { menu, product } = props;

  const version = parseInt(menu?.settings.PRODUCT_CARD_VERSION || 1);

  const renderProductCard = () => {
    if (version === 2) {
      return <ProductCardV2 product={product} />;
    } else {
      return <ProductCardV1 product={product} />;
    }
  };

  return (
    <div role="listitem" className={'product-card product-card-v' + version} data-product-id={product.id}>
      {renderProductCard()}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
