import React from 'react';
import UtilityService from '../services/utility';
import { MEDIA_BASE_URL } from '../utils/constant';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { connect } from 'react-redux';

function ProductFullImage(props) {
  const { product, menu } = props;
  const displayDefaultImageEnabled = menu?.settings?.DISPLAY_DEFUALT_PRODUCT_IMAGE_ENABLED === 'true';

  return (
    <div className="product-full-image">
      {product && product.media && product.media.length > 1 && (
        <Carousel showStatus={false} showThumbs={false}>
          {product.media.map((media) => {
            return (
              <div key={media.id}>
                {media.media.type === 'IMAGE' && <img alt="" src={MEDIA_BASE_URL + media.media.path} />}
                {media.media.type === 'VIDEO' && (
                  <video controls>
                    <source src={MEDIA_BASE_URL + media.media.path} type="video/mp4" />
                  </video>
                )}
              </div>
            );
          })}
        </Carousel>
      )}
      {product && (!product.media || product.media.length < 2) && (
        <img alt="" src={UtilityService.getProductImageURL(product, displayDefaultImageEnabled)} />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
  };
}

export default connect(mapStateToProps)(ProductFullImage);
