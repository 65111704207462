import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { connect } from 'react-redux';
import { setSelectedCategory } from '../../actions/menuActions';
import Icon from './Icon';
import Text from './Text';
import IconText from './IconText';

function CategoryNavigation(props) {
  const { menu } = props;

  const categories = props.menu?.categories
    ?.filter((c) => {
      return c.name !== null && c.name !== '' && parseInt(c.hidden_category) !== 1;
    })
    .sort((a, b) => a.sequence - b.sequence);

  const selectedCategory = props.menu?.categories.find((cat) => cat.id === menu.selectedCategory);

  const type = menu?.settings.CATEGORY_NAVIGATION_TYPE || 'TEXT';
  const displaySelectedCategoryName = menu?.settings?.DISPLATY_SELECTED_CATEGORY_NAME === 'true';

  return (
    <div className={'category-navigation category-navigation-' + type.toLowerCase()}>
      <div className="category-list">
        {categories &&
          categories.map((category) => {
            const active = menu.selectedCategory === category.id;
            if (type === 'ICONTEXT') {
              return (
                <IconText
                  key={category.id}
                  name={category.name}
                  src={category.icon}
                  active={active}
                  onClick={() => props.setSelectedCategory(category.id)}
                />
              );
            } else if (type === 'ICON') {
              return (
                <Icon key={category.id} src={category.thumbnail} active={active} onClick={() => props.setSelectedCategory(category.id)} />
              );
            } else {
              return <Text key={category.id} name={category.name} active={active} onClick={() => props.setSelectedCategory(category.id)} />;
            }
          })}
      </div>
      {displaySelectedCategoryName && selectedCategory && <div className="selected-category-name">{selectedCategory.name}</div>}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSelectedCategory: setSelectedCategory,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryNavigation);
