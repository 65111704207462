import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchShoppingCartDetails } from '../actions/shoppingCartActions';
import ShoppingCartService from '../services/shoppingCart';
import UtilityService from '../services/utility';
import FormatPrice from './FormatPrice';
import OrderItemAssociations from './OrderItemAssociations';
import OrderItemAttributes from './OrderItemAttributes';

function MiniCartItem(props) {
  const { t } = useTranslation();
  const { item, menu } = props;

  const resolveShoppingCartItemProduct = (item) => {
    if (props.menu && props.menu.products) {
      return props.menu.products.find((p) => parseInt(item.productId) === parseInt(p.id));
    }
    return null;
  };

  const product = resolveShoppingCartItemProduct(item);

  const handleRemoveItem = (item) => {
    ShoppingCartService.removeItemFromCart(props.qrcode.id, item.id).then(() => {
      props.fetchShoppingCartDetails(props.qrcode.id);
    });
  };

  const handleQuatityChange = (item, quantity) => {
    let newQuantity = parseInt(item.quantity) + quantity;
    if (newQuantity < 1) {
      newQuantity = 1;
    }
    if (newQuantity !== item.quantity) {
      ShoppingCartService.updateItemQuantity(props.qrcode.id, item.id, newQuantity).then(() => {
        props.fetchShoppingCartDetails(props.qrcode.id);
      });
    }
  };

  const resolveProductURL = () => {
    return '/m/' + props?.qrcode.uuid + '/menu/product/' + item?.productId;
  };

  const calculateItemPrice = (item) => {
    let total = 0;
    total += parseFloat(item.price);
    if (item.associations) {
      item.associations.forEach((ass) => {
        total += parseFloat(ass.price);
      });
    }
    return total;
  };

  return (
    <div className="product-container">
      <div className="product-image">
        <div className="product-image-wrapper">
          <Link to={resolveProductURL()}>
            <img alt="" src={UtilityService.getProductImageURL(product)} />
          </Link>
        </div>
      </div>
      <div className="product-details">
        <div className="product-name-and-description">
          <Link to={resolveProductURL()}>
            <p className="product-name">{product?.name}</p>
          </Link>
          {menu?.settings?.SHOW_DESCRIPTION_ON_CHECKOUT === 'true' && product?.description && (
            <p className="product-description">{product?.description}</p>
          )}
          <OrderItemAttributes item={item} />
          {item.associations && item.associations.length > 0 && <OrderItemAssociations item={item} />}
          {item?.comments && (
            <p className="item-comments">
              <span className="comments-title">{t('Comments')}:</span> {item.comments}
            </p>
          )}
        </div>
        <div className="product-price-and-quantity">
          <div className="product-price">
            <FormatPrice value={calculateItemPrice(item)} />
          </div>
          <div className="product-quantity">
            <div className="qty-group">
              <button className="qty-plus" onClick={() => handleQuatityChange(item, 1)}>
                +
              </button>
              <span>{item.quantity || 1}</span>
              <button className="qty-minus" onClick={() => handleQuatityChange(item, -1)}>
                -
              </button>
            </div>
          </div>
          <div className="product-remove-link">
            <p onClick={() => handleRemoveItem(item)}>{t('Remove')}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    shoppingCart: state.shoppingCart,
    qrcode: state.qrcode,
    menu: state.menu,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchShoppingCartDetails: fetchShoppingCartDetails,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MiniCartItem);
