const defaultObj = {};

const qrcode = {};

const qrcodeReducer = (state = qrcode, action) => {
  switch (action.type) {
    case 'SET_QR_CODE':
      return action.payload;
    case 'REST_QRCODE':
      return defaultObj;
    default:
      return state;
  }
};

export default qrcodeReducer;
