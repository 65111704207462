import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import UtilityService from '../services/utility';
import ProductPrice from './ProductPrice';

function SpecialsOffer(props) {
  const { t, menu, qrcode } = props;
  const offers = menu?.special_offers;

  const ref = React.useRef(null);

  const sideScroll = (step) => {
    let scrollAmount = 0;
    let distance = ref.current.offsetWidth - 100;

    const slideTimer = setInterval(() => {
      ref.current.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, 10);
  };

  return (
    <div className="section specials-offer">
      <div className="title">
        <h3>{t("Today's Specials Offers")}</h3>
      </div>
      <div className={'offer-list ' + (offers && offers.length === 1 ? 'one-offer' : '')} ref={ref}>
        {offers?.map((offer) => {
          const product = menu.products.find((p) => p.id == offer);
          return (
            <div role="listitem" className="offer" key={offer}>
              <Link to={'menu/offer/' + offer}>
                <div className="offer-image">
                  <img alt="" src={UtilityService.getProductImageURL(product)} />
                </div>
                <div className="offer-details">
                  <h3 className="title">{product?.name}</h3>
                  {qrcode.menu?.settings?.RATE_ENABLED === 'true' && (
                    <div className="product-rating">
                      <StarRatings
                        rating={2.5}
                        starDimension="15px"
                        starSpacing="5px"
                        starRatedColor="#5777ba"
                        numberOfStars={5}
                        name="rating"
                      />
                    </div>
                  )}
                  <p className="product-price">
                    <ProductPrice product={product} />
                  </p>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
      {offers && offers.length > 1 && (
        <>
          <span className="navigation right-navigation" onClick={() => sideScroll(10)}>
            <img src="/assets/icon/arrow-white.svg" alt="" />
          </span>
          <span className="navigation left-navigation" onClick={() => sideScroll(-10)}>
            <img src="/assets/icon/arrow-white.svg" alt="" />
          </span>
        </>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    qrcode: state.qrcode,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SpecialsOffer));
