import React from 'react';
import ImageWidget from './ImageWidget';
import { useGroupConfig } from '../hooks/useGroupConfig';

const Group = ({ group, menu }) => {
    const { productDetails, containerStyles, getItemComponent } = useGroupConfig(group, menu);

    const ImageStyle = {
        paddingTop: 8
    }

    return (
        <div style={ImageStyle}>
            <ImageWidget src={group.thumbnail} alt={group.name} categoryName={group.name} isGroup = "true" />
            {(group.name === 'MOCHI' || group.name === 'TAIYAKI') && (
                <div style={{ fontWeight: 600, padding:20, fontSize:20, display: 'flex', justifyContent:'center' }}>
                    {productDetails[0]?.price} / Piece
                </div>
            )}
            <div style={containerStyles}>
                {productDetails.map((product, index) => getItemComponent(product, index))}
            </div>
        </div>
    );
};

export default Group;
