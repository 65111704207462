import ReviewsService from '../services/reviews';

export function fetchProductReviews(productId) {
  return async (dispatch) => {
    return ReviewsService.fetchProductReviews(productId)
      .then((response) => response.json())
      .then((data) => {
        dispatch(setReviews(data));
      })
      .catch((error) => {
        dispatch(resetReviews());
      });
  };
}

function setReviews(data) {
  return { type: 'SET_REVIEWS', payload: data };
}

function resetReviews() {
  return { type: 'RESET_REVIEWS' };
}
