import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header';
import MenuGroupV2 from '../../components/MenuGroupV2';
import { MEDIA_BASE_URL } from '../../utils/constant';
import CategoryNavigation from '../../components/categoryNavigation';
import Text from '../../components/categoryNavigation/Text';

function ProductListPageV3(props) {
  const { uuid } = useParams();
  const { menu } = props;

  const [expand, setExpand] = useState(false);

  let showLocalSwitch = false;

  if (menu.supported_lang?.split(',').length > 1) {
    showLocalSwitch = true;
  }

  if (menu.supported_currency?.split(',').length > 1) {
    showLocalSwitch = true;
  }

  const skipHomePAge = !!menu?.settings?.SKIP_HOME_MAGE;

  const getMenuLogo = () => {
    let logo = '/assets/icon/default-menu-logo.svg';
    if (menu.logo) {
      logo = menu.logo;
      if (logo && !logo.startsWith('http')) {
        logo = MEDIA_BASE_URL + logo;
      }
    }
    return logo;
  };

  const categoryId = props.menu.selectedCategory;
  let category = null;
  if (categoryId != null && props.menu.categories != null) {
    category = props.menu.categories.filter((c) => {
      return c.id === categoryId;
    })[0];
  }

  const [selectedGroupId, setSelectedGroupId] = useState(category?.groups?.[0]?.id);

  return (
    <>
      <Header
        showBackButton={!skipHomePAge}
        showShareButton={true}
        showLocalSwitch={showLocalSwitch}
        showBusinessDetails={false}
        backURL={'/m/' + uuid}
      />
      <div>
        <div className="menu-logo">
          <img alt="" src={getMenuLogo()} />
        </div>

        <div
          className="social-links"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: 20,
          }}>
          <h2>تابعنا على</h2>
          <div
            className="social-links-container"
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: 20,
            }}>
            <div className="social-links-item">
              <a target="_blank" href="https://www.facebook.com/ultratech.utt" rel="noopener noreferrer">
                <img width={50} src="/assets/icon/social/facebook.svg" alt="ultratech" />
              </a>
            </div>
            <div className="social-links-item">
              <a target="_blank" href="https://www.instagram.com/ultratech.ut" rel="noopener noreferrer">
                <img width={50} src="/assets/icon/social/instagram.svg" alt="ultratech" />
              </a>
            </div>
            <div className="social-links-item">
              <a href="tel:+972507301710">
                <img width={50} src="/assets/icon/social/phone.svg" alt="ultratech" />
              </a>
            </div>
          </div>
        </div>

        <div
          className="category-navigation category-navigation-text"
          style={{ display: 'flex', gap: 10, marginBottom: 10, justifyContent: 'center' }}>
          <div
            className="category-list"
            style={{
              border: '1px solid #5777ba',
              borderRadius: 10,
              padding: 5,
              fontSize: 22,
              paddingLeft: 10,
              paddingRight: 10,
              backgroundColor: selectedGroupId == 108 ? '#5777ba' : '#FFFFFF',
              color: selectedGroupId == 108 ? '#FFFFFF' : '#000000',
            }}
            onClick={() => setSelectedGroupId(108)}>
            الوجبات الرئسية
          </div>
          <div
            className="category-list"
            onClick={() => setSelectedGroupId(109)}
            style={{
              border: '1px solid #5777ba',
              borderRadius: 10,
              paddingLeft: 10,
              paddingRight: 10,
              padding: 5,
              fontSize: 22,
              backgroundColor: selectedGroupId == 109 ? '#5777ba' : '#FFFFFF',
              color: selectedGroupId == 109 ? '#FFFFFF' : '#000000',
            }}>
            وجبات الاطفال
          </div>
        </div>

        <div
          style={{
            textAlign: 'center',
            position: 'relative',
            padding: 5,
            borderTop: '1px dashed #0F0F0F',
            borderBottom: '1px dashed #0F0F0F',
          }}>
          <h2 style={{ margin: 0 }}>السلطات</h2>
          <img
            src={`/assets/icon/arrow-${expand ? 'down' : 'up'}-white.svg`}
            style={{ position: 'absolute', left: 0, top: 6 }}
            width={30}
            onClick={() => setExpand(!expand)}
          />
          <div style={{ display: expand ? 'block' : 'none' }}>
            <ul style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 10 }}>
              <li style={{ textAlign: 'initial', fontWeight: 800 }}>مخلل مكبوس</li>
              <li style={{ textAlign: 'initial', fontWeight: 800 }}>ملفوف احمر</li>
              <li style={{ textAlign: 'initial', fontWeight: 800 }}>ملفوف ابيض</li>
              <li style={{ textAlign: 'initial', fontWeight: 800 }}>سلطة تركية</li>
              <li style={{ textAlign: 'initial', fontWeight: 800 }}>باذنجان حار</li>
              <li style={{ textAlign: 'initial', fontWeight: 800 }}>تبولة</li>
              <li style={{ textAlign: 'initial', fontWeight: 800 }}>بندورة حارة</li>
              <li style={{ textAlign: 'initial', fontWeight: 800 }}>بطاطا حارة</li>
              <li style={{ textAlign: 'initial', fontWeight: 800 }}>خيار بلبن</li>
              <li style={{ textAlign: 'initial', fontWeight: 800 }}>حمص</li>
              <li style={{ textAlign: 'initial', fontWeight: 800 }}>جزر مغربي</li>
              <li style={{ textAlign: 'initial', fontWeight: 800 }}>شمندر</li>
              <li style={{ textAlign: 'initial', fontWeight: 800 }}>سلطة معكرونة</li>
            </ul>
          </div>
        </div>

        <MenuGroupV2 selectedGroupId={selectedGroupId} />
      </div>
    </>
  );
}

// mapStateToProps maps the state from the Redux store to props of the component
const mapStateToProps = (state) => {
  return {
    menu: state.menu,
  };
};

// mapDispatchToProps maps the dispatch of Redux actions to props of the component
const mapDispatchToProps = (dispatch) => {
  return {};
};

// connect the component with the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(ProductListPageV3);
