import { useMemo } from 'react';

const useCategories = (menu, categoryId = null) => {
  return useMemo(() => {
    if (!menu || !menu.categories) {
      return [];
    }
    if (categoryId) {
      const category = menu.categories.find(c => c.id === categoryId);
      return category ? category.groups : [];
    }
    return menu.categories;
  }, [menu, categoryId]);
};

export default useCategories;
