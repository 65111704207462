import { combineReducers } from 'redux';
import MenuReducer from './menuReducer';
import QRCodeReducer from './qrcodeReducer';
import ShoppingCartReducer from './shoppingCartReducer';
import ReviewsReducer from './reviewsReducer';
import ConfigReducer from './configReducer';

const rootReducer = combineReducers({
  menu: MenuReducer,
  qrcode: QRCodeReducer,
  shoppingCart: ShoppingCartReducer,
  reviews: ReviewsReducer,
  config: ConfigReducer,
});

export default rootReducer;
