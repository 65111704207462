import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setConfig } from '../actions/configActions';
import { SUPPORTED_SORT } from '../utils/constant';

function ProductSort(props) {
  const { config } = props;
  const { t } = useTranslation();

  const setProductSort = (event) => {
    props.setConfig({ productSort: event.target.value });
  };

  return (
    <div className="sort-options">
      <select onChange={setProductSort} value={config.productSort}>
        {SUPPORTED_SORT.map((sort) => {
          return (
            <option key={sort.value} value={sort.value}>
              {t(sort.title)}
            </option>
          );
        })}
      </select>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    config: state.config,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setConfig: setConfig,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSort);
