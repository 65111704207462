import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class HomePageV2 extends Component {
  render() {
    return (
      <div>
        <div className="actions">
          <div className="button-browse-all-menu">
            <Link to="menu" className="btn-secondary button">
              Browse menu
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

// mapStateToProps maps the state from the Redux store to props of the component
const mapStateToProps = (state) => {
  return {
    menu: state.menu,
    config: state.config,
  };
};

// mapDispatchToProps maps the dispatch of Redux actions to props of the component
const mapDispatchToProps = (dispatch) => {
  return {};
};

// connect the component with the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(HomePageV2);
