import React from 'react';
import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TelegramIcon,
  WhatsappIcon,
  LinkedinIcon,
  PinterestIcon,
  TwitterIcon,
  EmailIcon,
  ViberIcon,
} from 'react-share';
import { MEDIA_BASE_URL } from '../utils/constant';
import CopyToClipboard from 'react-copy-to-clipboard';

const customStyles = {
  overlay: { backgroundColor: 'rgba(0, 0, 0, 0.45)' },
  content: {
    top: '50%',
    right: 20,
    left: 20,
    bottom: 'auto',
    padding: 25,
    borderRadius: 0,
    maxWidth: 768,
  },
};

function ShareModal(props) {
  const { t, menu } = props;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  const openModal = () => {
    setIsOpen(true);
    setCopied(false);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const getMenuLogo = () => {
    let logo = '/assets/icon/default-menu-logo.svg';
    if (menu.media) {
      menu.media.forEach((m) => {
        if (m.type === 'LOGO' && m.media && m.media.path && m.media.path.length > 0) {
          logo = MEDIA_BASE_URL + m.media.path;
        }
      });
    }
    return logo;
  };

  const shareUrl = window.location.href;
  const title = menu?.description?.name;
  const media = getMenuLogo();

  return (
    <div className="sahre-container">
      <img src="/assets/icon/share.svg" alt="" onClick={openModal} />

      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} ariaHideApp={false}>
        <div className="share-modal">
          <img className="share-modal-close" alt="" src="/assets/icon/close.svg" onClick={closeModal} />
          <p className="share-modal-title">{t('Share our menu with your friends & family')}</p>
          <div className="share-modal-social-links">
            <FacebookShareButton url={shareUrl} title={title}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>

            <TwitterShareButton url={shareUrl} title={title}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>

            <TelegramShareButton url={shareUrl} title={title}>
              <TelegramIcon size={32} round />
            </TelegramShareButton>

            <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>

            <LinkedinShareButton url={shareUrl}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>

            <PinterestShareButton url={String(window.location)} media={media}>
              <PinterestIcon size={32} round />
            </PinterestShareButton>

            <ViberShareButton url={shareUrl} title={title}>
              <ViberIcon size={32} round />
            </ViberShareButton>

            <EmailShareButton url={shareUrl} subject={title} body="">
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>
          <div className="share-modal-copy-link">
            {!copied && (
              <>
                <input type="text" disabled={true} value={shareUrl} />
                <CopyToClipboard text={shareUrl} onCopy={() => setCopied(true)}>
                  <div className="copy-btn">{t('Copy')}</div>
                </CopyToClipboard>
              </>
            )}
            {copied && <span className="success-message">{t('Copied')}</span>}
          </div>
        </div>
      </Modal>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ShareModal));
