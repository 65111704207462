import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setConfig } from '../actions/configActions';
import { updateShoppingCart } from '../actions/shoppingCartActions';
import { KEYS } from '../utils/constant';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    position: 'initial',
    padding: 10,
    margin: 20,
    borderRadius: 0,
    maxWidth: 420,
    width: '100%',
  },
};

function DineInTakeawaySwitchModal(props) {
  const { t, config, qrcode } = props;
  const [modalIsOpen, setIsOpen] = useState(!config.dineInOrTakeaway);

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSelect = (type) => {
    if (type === 'DINE_IN') {
      sessionStorage.setItem(KEYS.ALLOW_TO_ORDER, 'false');
    }

    sessionStorage.setItem(KEYS.DINING_OPTION, type);
    props.setConfig({ dineInOrTakeaway: type });
    props.updateShoppingCart(qrcode.id, { type: type });
    closeModal();
  };

  return (
    <div>
      <Modal isOpen={modalIsOpen} onRequestClose={() => {}} style={customStyles} ariaHideApp={false}>
        <div className="dine-in-takeaway-switch-modal">
          <div className="title">
            <h2>{t("What's Your Favorite")}</h2>
          </div>
          <div className="options">
            <div className="option" onClick={() => handleSelect('DINE_IN')}>
              <div className="icon">
                <img src="/assets/icon/dinein.png" />
              </div>
              <h2>{t('Dine In')}</h2>
            </div>
            <div className="option" onClick={() => handleSelect('TAKE_AWAY')}>
              <div className="icon">
                <img src="/assets/icon/takeaway.png" />
              </div>
              <h2>{t('Take Away')}</h2>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    qrcode: state.qrcode,
    config: state.config,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setConfig: setConfig,
      updateShoppingCart: updateShoppingCart,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DineInTakeawaySwitchModal));
