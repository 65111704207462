import React from 'react';
import Layout from './components/Layout';
import ImageWidget from './components/ImageWidget';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import useCategories from './hooks/useCategories';

const HomePage = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { menu } = props; 
  const categories = useCategories(menu);

  const handleCategoryClick = (categoryId) => {
  const newPath = `${location.pathname}/category/${categoryId}`;
    navigate(newPath);
  };

  const homepageStyles = {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '20px',
    textAlign: 'center',
  };

  const headerStyles = {
   
    fontFamily: 'NexaSlab, Sans-serif',
    fontSize: '44px',
    fontWeight: 100,
    letterSpacing: '-3px',
    paddingTop: 22
  };

  const categoryStyles = {
    display: 'flex',
    flexDirection: 'column',
    gap: 35,
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <Layout>
      <div style={homepageStyles}>
        <h1 style={headerStyles}>UNCLE OSAKA</h1>
        <div style={categoryStyles}>
         {categories.map(category => (
            <ImageWidget
              key={category.id}
              src={category.thumbnail}
              alt={category.name}
              categoryName={category.name.toUpperCase()}
              onClick={() => handleCategoryClick(category.id)}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  menu: state.menu,
  config: state.config,
  qrcode: state.qrcode,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HomePage));
