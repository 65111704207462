import React from 'react';

const ImageWidget = ({ src, alt, categoryName, onClick, isGroup }) => {
  const imageStyles = {
    width: '350px',
    height: 'auto',
    border: '1px solid white',
    borderRadius: `10px`,
    ...(!isGroup && { boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }),
    cursor: 'pointer',
  };

  const categoryItemStyles = {
    position: 'relative',
    textAlign: 'center',
  };

  const categoryNameStyles = {
    fontSize: 19,
    textTransform: 'uppercase',
    fontWeight: 600,
    padding: 5
  };

  return (
    <div style={categoryItemStyles} onClick={onClick}>
      <img src={src} alt={alt} style={imageStyles} />
      <div style={categoryNameStyles}>{categoryName}</div>
    </div>
  );
};

export default ImageWidget;
