import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CustomerInfo from '../components/order/CustomerInfo';
import OrderActions from '../components/order/OrderActions';
import OrderComments from '../components/order/OrderComments';
import OrderHeader from '../components/order/OrderHeader';
import OrderItems from '../components/order/OrderItems';
import OrderPayments from '../components/order/OrderPayments';
import OrderSummary from '../components/order/OrderSummary';
import TableSelection from '../components/order/TableSelection';
import DefaultLayout from '../layout/DefaultLayout';

function CheckoutContainer(props) {
  const { menu } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <DefaultLayout className="template-v1">
        <div className="section checkout-container">
          <OrderHeader />
          <OrderItems />
          <OrderSummary />
          <OrderPayments />
          {menu?.settings?.SHOW_CUSTOMER_FORM_IN_CHECKOUT === 'true' && <CustomerInfo />}
          {menu?.settings?.SHOW_TABLE_SELECTOR_IN_CHECKOUT === 'true' && <TableSelection />}
          <OrderComments />
          <OrderActions />
        </div>
      </DefaultLayout>
    </>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CheckoutContainer));
