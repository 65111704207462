import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { fetchShoppingCartDetails } from '../actions/shoppingCartActions';

function OrderItemAssociations(props) {
  const { t } = useTranslation();
  const { item, menu } = props;

  const additions = item.associations.filter((a) => a.type === 'ADDITIONS' || a.type === 'SAUCE');
  const options = item.associations.filter((a) => a.type === 'WITHOUT');
  const ingredients = item.associations.filter((a) => a.type === 'INGREDIENTS');

  return (
    <div className="order-item-associations">
      {additions.length > 0 && (
        <div>
          <span className="associations-title">{t('Additions')}: </span>
          {additions.map((ass, index) => {
            const prod = menu?.products?.find((p) => parseInt(ass.catentry_id) === parseInt(p.id));
            return (
              <span key={index}>
                {prod.name}
                {index < additions.length - 1 ? ', ' : ''}
              </span>
            );
          })}
        </div>
      )}

      {options.length > 0 && (
        <div>
          <span className="associations-title">{t('Options')}: </span>
          {options.map((ass, index) => {
            const prod = menu?.products?.find((p) => parseInt(ass.catentry_id) === parseInt(p.id));
            return (
              <span key={index}>
                {ass.type === 'WITHOUT' && prod.partnumber !== 'FX153' ? t('without') + ' ' : ''} {prod.name}
                {index < options.length - 1 ? ', ' : ''}
              </span>
            );
          })}
        </div>
      )}

      {ingredients.length > 0 && (
        <div>
          <span className="associations-title">{t('Ingredients')}: </span>
          {ingredients.map((ass, index) => {
            const prod = menu?.products?.find((p) => parseInt(ass.catentry_id) === parseInt(p.id));
            return (
              <span key={index}>
                {prod.name}
                {index < ingredients.length - 1 ? ', ' : ''}
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    shoppingCart: state.shoppingCart,
    qrcode: state.qrcode,
    menu: state.menu,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchShoppingCartDetails: fetchShoppingCartDetails,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderItemAssociations);
