import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProductListContainer from './template/ProductListContainer';
import HomePageContainer from './template/HomePageContainer';
import ProductDetailsContainer from './template/ProductDetailsContainer';
import CheckoutContainer from './template/CheckoutContainer';
import OrderConfirmationContainer from './template/OrderConfirmationContainer';
import ActiveOrdersContainer from './template/ActiveOrdersContainer';
import NotFoundPage from './template/NotFoundPage';
import HomePage from './template/uncleosaka/HomePage';
import ProductListPage from './template/uncleosaka/ProductList';

const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <main className="main">
        <Routes>
          <Route path="/m/lb7vz-hkia7-uoj57-eqhsx-uncjy">
          <Route index element={<HomePage />} />
          <Route path="category/:id" element={<ProductListPage />} /> 
          </Route>
          <Route path="/m/:uuid">
            <Route index element={<HomePageContainer />} />
            <Route path="menu" element={<ProductListContainer />} />
            <Route path="checkout" element={<CheckoutContainer />} />
            <Route path="orders" element={<ActiveOrdersContainer />} />
            <Route path="confirmation" element={<OrderConfirmationContainer />} />
            <Route path="menu/product/:id" element={<ProductDetailsContainer />} />
            <Route path="menu/offer/:id" element={<ProductDetailsContainer isOffer={true} />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </main>
    </Suspense>
  );
}

export default App;
