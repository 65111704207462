import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import FormatPrice from '../FormatPrice';

function OrderSummary(props) {
  const { shoppingCart, qrcode, t } = props;

  return (
    <div className="order-summary">
      <div className="order-summary-line order-subtotal">
        <p className="line-title">{t('Sub Total')}:</p>
        <p className="line-value">
          <FormatPrice value={shoppingCart?.total_items || 0} />
        </p>
      </div>
      {qrcode.menu?.settings?.ADDITIONAL_SERVICE_ENABLED === 'true' && (
        <div className="order-summary-line order-additional-service">
          <p className="line-title">{t('Additional Service Fee')}:</p>
          <p className="line-value">
            <FormatPrice value={shoppingCart?.additional_service || 0} />
          </p>
        </div>
      )}
      {shoppingCart?.total_adjustment && (
        <div className="order-summary-line order-adjustment">
          <p className="line-title">{t('Discount')}:</p>
          <p className="line-value">
            <FormatPrice value={shoppingCart?.total_adjustment || 0} />
          </p>
        </div>
      )}
      {qrcode.menu?.settings?.TAX_ENABLED === 'true' && (
        <div className="order-summary-line order-tax">
          <p className="line-title">{t('Value Added Tax (VAT)')}:</p>
          <p className="line-value">
            <FormatPrice value={shoppingCart?.total_tax || 0} />
          </p>
        </div>
      )}
      <div className="order-summary-line order-total">
        <p className="line-title">{t('Order Total')}:</p>
        <p className="line-value">
          <FormatPrice value={shoppingCart?.amount || 0} />
        </p>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    shoppingCart: state.shoppingCart,
    qrcode: state.qrcode,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderSummary));
