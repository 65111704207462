import React from 'react';
import { connect } from 'react-redux';

function MenuSocialLink(props) {
  const { menu } = props;

  return (
    <>
      <div className="social-links">
        {menu?.settings?.FACEBOOK_LINK !== '' && (
          <a target="_blank" href={menu?.settings?.FACEBOOK_LINK} rel="noopener noreferrer">
            <img src="/assets/icon/facebook.png" />
          </a>
        )}
        {menu?.settings?.INSTAGRAM_LINK !== '' && (
          <a target="_blank" href={menu?.settings?.INSTAGRAM_LINK} rel="noopener noreferrer">
            <img src="/assets/icon/instagram.png" alt="" />
          </a>
        )}
        {menu?.settings?.PHONE_NUMBER !== '' && (
          <a target="_blank" href={'tel:' + menu?.settings?.PHONE_NUMBER} rel="noopener noreferrer">
            <img src="/assets/icon/phone.png" alt="" />
          </a>
        )}
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
  };
}

export default connect(mapStateToProps)(MenuSocialLink);
