import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setShoppingCartComment, setShoppingCartCustomerName, setShoppingCartCustomerMobile } from '../../actions/shoppingCartActions';

function CustomerInfo(props) {
  const { t, shoppingCart } = props;

  const handleCustomerNameChanged = (event) => {
    props.setShoppingCartCustomerName(event.target.value);
  };

  const handleCustomerMobileChanged = (event) => {
    props.setShoppingCartCustomerMobile(event.target.value);
  };

  return (
    <div className="order-customer-info">
      <div>
        <p className="order-customer-info-title">{t('Name')}:</p>
        <input placeholder={t('Name')} value={shoppingCart?.customerName} onChange={handleCustomerNameChanged} />
      </div>
      <div>
        <p className="order-customer-info-title">{t('Mobile')}:</p>
        <input placeholder={t('Mobile')} value={shoppingCart?.customerMobile} onChange={handleCustomerMobileChanged} />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    shoppingCart: state.shoppingCart,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setShoppingCartComment: setShoppingCartComment,
      setShoppingCartCustomerName: setShoppingCartCustomerName,
      setShoppingCartCustomerMobile: setShoppingCartCustomerMobile,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerInfo));
