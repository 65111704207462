import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import FooterNavigation from '../../components/FooterNavigation';
import Header from '../../components/Header';
import MenuFilters from '../../components/MenuFilters';
import MenuGroup from '../../components/MenuGroup';
import MenuSocialLink from '../../components/MenuSocialLink';
import MiniCart from '../../components/MiniCart';
import CategoryNavigation from '../../components/categoryNavigation';

function ProductListPageV1(props) {
  const { uuid } = useParams();
  const { menu, qrcode } = props;

  let showLocalSwitch = false;

  if (menu.supported_lang?.split(',').length > 1) {
    showLocalSwitch = true;
  }

  if (menu.supported_currency?.split(',').length > 1) {
    showLocalSwitch = true;
  }

  return (
    <>
      <Header
        showBackButton={true}
        showShareButton={true}
        showLocalSwitch={showLocalSwitch}
        showBusinessDetails={true}
        backURL={'/m/' + uuid}
      />
      {menu?.settings?.SOCIAL_LINK_ENABLED === 'true' && <MenuSocialLink />}
      <h1 className="brand-name">{menu.name}</h1>
      <CategoryNavigation />
      {(menu?.settings?.SORT_ENABLED === 'true' || qrcode?.menu?.settings?.LAYOUT_SWITCH_ENABLED === 'true') && <MenuFilters />}
      <MenuGroup />
      {!!menu?.settings?.ALLOW_TO_ORDER && <MiniCart />}
      {!!menu?.settings?.FOOTER_NAVIGATION_ENABLED && <FooterNavigation />}
    </>
  );
}

// mapStateToProps maps the state from the Redux store to props of the component
const mapStateToProps = (state) => {
  return {
    menu: state.menu,
    config: state.config,
    qrcode: state.qrcode,
  };
};

// mapDispatchToProps maps the dispatch of Redux actions to props of the component
const mapDispatchToProps = (dispatch) => {
  return {};
};

// connect the component with the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(ProductListPageV1);
