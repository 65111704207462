const defaultObj = null;

const reviews = null;

const reviewsReducer = (state = reviews, action) => {
  switch (action.type) {
    case 'SET_REVIEWS':
      return action.payload;
    case 'REST_REVIEWS':
      return defaultObj;
    default:
      return state;
  }
};

export default reviewsReducer;
