import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import AddToCart from './../AddToCart';
import ProductImage from './../ProductImage';
import ProductPrice from './../ProductPrice';
import SelectOptions from './../SelectOptions';
import { useTranslation } from 'react-i18next';
import UtilityService from '../../services/utility';

function ProductCardV1(props) {
  const { menu, product } = props;
  const { t } = useTranslation();

  let items = product.items;
  const dineInOrTakeaway = props.config.dineInOrTakeaway;
  const dineInExtraCostEnabled = menu.settings.DINE_IN_EXTRA_COST_ENABLED;
  if (!!dineInExtraCostEnabled && dineInOrTakeaway === 'DINE_IN') {
    items = items?.filter((p) => UtilityService.isAllowToDineIn(p));
  }

  const RenderCTAButton = () => {
    if (product.type === 'COMPLEX_PRODUCT' && items.length > 1) {
      if (items.length > 1) {
        return <SelectOptions productId={product.id} />;
      } else if (items.length === 1) {
        return <AddToCart productId={items[0].id} />;
      }
    } else if (product.type === 'SIMPLE_PRODUCT') {
      if (product.merchandisingAssociations && product.merchandisingAssociations.length > 0) {
        return <SelectOptions productId={product.id} />;
      }
      return <AddToCart productId={product.id} />;
    }
    return null;
  };

  return (
    <div className="product-container">
      <ProductImage product={product} enablePDPLink={true} />

      <div className="product-details">
        <div className="product-name">
          <Link to={'product/' + product.id}>
            <p className="product-name">{product?.name}</p>
          </Link>
        </div>

        <div className="product-description">
          <p>{product?.description}</p>
        </div>

        <div className="product-price">
          <ProductPrice product={product} />
        </div>

        {props.rateEnabled && (
          <div className="product-rating">
            <StarRatings
              rating={parseFloat(product?.rate || 0)}
              starDimension="15px"
              starSpacing="5px"
              starRatedColor="#5777ba"
              numberOfStars={1}
              name="rating"
            />
            <span>
              {parseFloat(product.rate || 0).toFixed(1)} / 5.0 ({parseInt(product.total_review || 0)} {t('reviews')})
            </span>
          </div>
        )}

        <div className="product-properties">
          {product.properties &&
            product.properties.map((property, index) => {
              return (
                <div className="product-property" key={index}>
                  <img alt="" className="product-property-icon" src={property.icon} />
                  <span className="product-property-name">{property.name}</span>
                </div>
              );
            })}
        </div>

        {props.allowToOrder && (
          <div className="product-actions">
            <RenderCTAButton />
          </div>
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    config: state.config,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardV1);
