import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

function OrderHeader(props) {
  const { t } = props;

  return (
    <div className="active-orders-header">
      <Link className="back-link" to="../menu">
        <img alt="" src="/assets/icon/back-arrow.svg" />
      </Link>
      <h2 className="order-header-title">{t('Order List')}</h2>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    shoppingCart: state.shoppingCart,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderHeader));
