import * as urls from '../utils/urls';

const MenuService = {
  getMenuJSON: function (menuId, langId, version) {
    return fetch(urls.GET_MENU_JSON_FILE_URL.replace('{menuId}', menuId).replace('{langId}', langId).replace('{version}', version));
  },

  getQRCode: function (uuid) {
    return fetch(urls.GET_QR_CODE_URL.replace('{uuid}', uuid));
  },

  sendReview: function (productId, rate, name, text) {
    return fetch(urls.SEND_PRODUCT_REVIEW_URL, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        productId: productId,
        rate: rate,
        name: name,
        text: text,
      }),
    });
  },

  sendTrackEvent: function (event, data) {
    return fetch(urls.TRACK_EVENT_URL, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ event: event, data: data }),
    }).catch((error) => {
      console.error('There was an error!', error);
    });
  },
};

export default MenuService;
