import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import DefaultLayout from "../layout/DefaultLayout";
import ProductListPageV1 from "./productListPage/ProductListPageV1";
import ProductListPageV2 from "./productListPage/ProductListPageV2";
import ProductListPageV3 from "./productListPage/ProductListPageV3";

function ProductListContainer(props) {
  const { menu } = props;

  let templateVersion = 1;
  if (menu && menu.settings) {
    templateVersion = parseInt(menu.settings.PLP_VERSION || 1);
  }

  const renderTempalte = () => {
    if (templateVersion === 1) {
      return <ProductListPageV1 />;
    } else if (templateVersion === 2) {
      return <ProductListPageV2 />;
    } else if (templateVersion === 3) {
      return <ProductListPageV3 />;
    } else {
      return <ProductListPageV1 />;
    }
  };

  return (
    <>
      <DefaultLayout className={"template-v" + templateVersion}>
        <div className="section">{renderTempalte()}</div>
      </DefaultLayout>
    </>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    qrcode: state.qrcode,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductListContainer);
