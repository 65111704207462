const defaultObj = {};

const cart = {};

const shoppingCartReducer = (state = cart, action) => {
  switch (action.type) {
    case 'SET_SHOPPING_CART':
      return action.payload;
    case 'SET_SHOPPING_CART_COMMENTS':
      return { ...state, comments: action.payload };
    case 'SET_SHOPPING_CART_NAME':
      return { ...state, customerName: action.payload };
    case 'SET_SHOPPING_CART_MOBILE':
      return { ...state, customerMobile: action.payload };
    case 'SET_SHOPPING_CART_TABLE_NUMBER':
      return { ...state, tableNumber: action.payload };
    case 'REST_SHOPPING_CART':
      return defaultObj;
    default:
      return state;
  }
};

export default shoppingCartReducer;
