import MenuService from '../services/menu';

export function fetchQRCode(uuid) {
  return async (dispatch) => {
    const response = await MenuService.getQRCode(uuid);
    const data = await response.json();
    dispatch(setQRCode(data));
    return data;
  };
}

function setQRCode(qrcode) {
  return { type: 'SET_QR_CODE', payload: qrcode };
}
