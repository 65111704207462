import React from 'react';
import useProductInfoByGroupId from './useProductInfoByGroupId';
import ItemThumbnail from '../components/ItemThumbnail';
import ItemPrice from '../components/ItemPrice';

export const useGroupConfig = (group, menu) => {
    const productDetails = useProductInfoByGroupId(group.items, menu);

    const containerStyles = () => {
        if (['MOCHI', 'TAIYAKI'].includes(group.name)) {
            return {
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                fontSize: 10,
                paddingBottom:12
            };
        }
        return {
                paddingBottom:12
        };
    };

    const getItemComponent = (product, index) => {
        if (group.name === 'MOCHI' || group.name === 'TAIYAKI') {
            return <ItemThumbnail key={product.id} item={product} index={index} />;
        } 
        else if (group.name === 'CHEESECAKE') {
            return <ItemPrice key={product.id} item={product} index={index} showThumbnail={true} />;
        }
        else {
            return <ItemPrice key={product.id} item={product} index={index} showThumbnail={true} />;
        }
    };

    return { productDetails, containerStyles: containerStyles(), getItemComponent };
};
