import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import DefaultLayout from '../layout/DefaultLayout';
import HomePageV1 from './homePage/HomePageV1';
import HomePageV2 from './homePage/HomePageV2';
import { useNavigate } from 'react-router-dom';

function HomePageContainer(props) {
  const { menu, config } = props;
  const navigate = useNavigate();

  let templateVersion = 1;
  if (menu && menu.home_page_template) {
    templateVersion = menu.home_page_template || 1;
  }

  const renderTempalte = () => {
    if (templateVersion === 1) {
      return <HomePageV1 />;
    } else if (templateVersion === 2) {
      return <HomePageV2 />;
    } else {
      return <HomePageV1 />;
    }
  };

  useEffect(() => {
    if (!!menu?.settings?.SKIP_HOME_MAGE) {
      navigate('menu');
    }
  }, [props]);

  return (
    <DefaultLayout className={'template-v' + templateVersion}>
      {menu && menu.id && config.currentLanguage && renderTempalte()}
    </DefaultLayout>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    config: state.config,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
