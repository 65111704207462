import React from 'react';
import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import StarRatings from 'react-star-ratings';
import { bindActionCreators } from 'redux';
import MenuService from '../services/menu';

const customStyles = {
  overlay: { backgroundColor: 'rgba(0, 0, 0, 0.45)' },
  content: {
    top: '50%',
    right: 20,
    left: 20,
    bottom: 'auto',
    padding: 10,
    borderRadius: 0,
    maxWidth: 768,
  },
};

function WriteReviewModal(props) {
  const { t, productId } = props;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [rateValue, setRateValue] = useState(0);
  const [name, setName] = useState('');
  const [reviewText, setReviewText] = useState('');
  const [error, setError] = useState('');

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const submitReview = () => {
    setError('');
    MenuService.sendReview(productId, rateValue, name, reviewText)
      .then((response) => response.json())
      .then((data) => {
        closeModal();
      })
      .catch((error) => {
        setError('Unable to submit your review, try again later!');
      });
  };

  const changeRating = (newRating) => {
    setRateValue(newRating);
  };

  return (
    <div>
      <button className="product-write-review-btn" onClick={openModal}>
        {t('Write A Review')}
      </button>

      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <div className="product-write-review-modal">
          <p className="title">{t('Write A Review')}</p>
          <div className="rating">
            <StarRatings
              changeRating={changeRating}
              rating={rateValue}
              starDimension="25px"
              starSpacing="8px"
              starRatedColor="#5777ba"
              starHoverColor="#5777ba"
              numberOfStars={5}
              name="rating"
            />
          </div>
          <div className="form">
            <input
              placeholder={t('Your Name')}
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <textarea
              placeholder={t('Write Your Review')}
              value={reviewText}
              onChange={(event) => {
                setReviewText(event.target.value);
              }}></textarea>
          </div>
          <p className="review-note">{t('Your review will be posted publicly on the menu after approved.')}</p>
          <span className="error">{error}</span>
          <div className="actions">
            <button className="submit-btn" onClick={submitReview}>
              {t('Submit')}
            </button>
            <button className="cancel-btn" onClick={closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WriteReviewModal));
