export const SUPPORTED_CURRENCY = [
  { id: 1, code: 'USD', symbol: '$', flag: 'flag-icon-us', title: 'US Dollar' },
  { id: 2, code: 'NIS', symbol: '₪', flag: 'flag-icon-il', title: 'Israeli Shekel' },
  { id: 4, code: 'EUR', symbol: '€', flag: 'flag-icon-eu', title: 'Euro' },
  { id: 3, code: 'JOD', symbol: 'د.ا', flag: 'flag-icon-jo', title: 'Jordanian Dinar' },
];

export const SUPPORTED_LANGUAGE = [
  { id: 1, code: 'en', dir: 'ltr', title: 'English', icon: 'flag-icon flag-icon-us ' },
  { id: 2, code: 'ar', dir: 'rtl', title: 'العربية', icon: 'flag-icon flag-icon-sa' },
  { id: 3, code: 'he', dir: 'rtl', title: 'עִברִית', icon: 'flag-icon flag-icon-il' },
];

export const MEDIA_BASE_URL = process.env.REACT_APP_STORAGE_BASE_URL;

export const SUPPORTED_LAYOUT = ['small-grid', 'list', 'large-grid'];

export const SUPPORTED_SORT = [
  { value: 'sequence', title: 'Sort by: Relevance' },
  { value: 'price', title: 'Sort by: Price' },
  { value: 'name', title: 'Sort by: Name' },
  { value: 'rate', title: 'Sort by: Rate' },
];

export const KEYS = {
  ALLOW_TO_ORDER: 'ALLOW_TO_ORDER',
  DINING_OPTION: 'DINING_OPTION',
};
