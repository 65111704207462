import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header';
import MenuGroup from '../../components/MenuGroup';
import CategoryNavigation from '../../components/categoryNavigation';
import { MEDIA_BASE_URL } from '../../utils/constant';

function ProductListPageV2(props) {
  const { uuid } = useParams();
  const { menu } = props;

  let showLocalSwitch = false;

  if (menu.supported_lang?.split(',').length > 1) {
    showLocalSwitch = true;
  }

  if (menu.supported_currency?.split(',').length > 1) {
    showLocalSwitch = true;
  }

  const skipHomePAge = !!menu?.settings?.SKIP_HOME_MAGE;

  const getMenuLogo = () => {
    let logo = '/assets/icon/default-menu-logo.svg';
    if (menu.logo) {
      logo = menu.logo;
      if (logo && !logo.startsWith('http')) {
        logo = MEDIA_BASE_URL + logo;
      }
    }
    return logo;
  };

  return (
    <>
      <Header
        showBackButton={!skipHomePAge}
        showShareButton={true}
        showLocalSwitch={showLocalSwitch}
        showBusinessDetails={false}
        backURL={'/m/' + uuid}
      />
      <div>
        <div className="menu-logo">
          <img alt="" src={getMenuLogo()} />
        </div>
        <CategoryNavigation />

        <MenuGroup />
      </div>
    </>
  );
}

// mapStateToProps maps the state from the Redux store to props of the component
const mapStateToProps = (state) => {
  return {
    menu: state.menu,
  };
};

// mapDispatchToProps maps the dispatch of Redux actions to props of the component
const mapDispatchToProps = (dispatch) => {
  return {};
};

// connect the component with the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(ProductListPageV2);
