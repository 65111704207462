import { SUPPORTED_CURRENCY, SUPPORTED_LANGUAGE } from './constant';

export function getLanguage(langId) {
  for (let index = 0; index < SUPPORTED_LANGUAGE.length; index++) {
    let lang = SUPPORTED_LANGUAGE[index];
    if (lang.id === parseInt(langId)) {
      return lang;
    }
  }
  return null;
}

export function getCurrency(currencyId) {
  for (let index = 0; index < SUPPORTED_CURRENCY.length; index++) {
    let currency = SUPPORTED_CURRENCY[index];
    if (currency.id === parseInt(currencyId)) {
      return currency;
    }
  }
  return null;
}

export function getCurrentLanguage(menuId) {
  const lang = JSON.parse(localStorage.getItem('menu_language_' + menuId));
  return lang;
}

export function getCurrentCurrency(menuId) {
  const currency = JSON.parse(localStorage.getItem('menu_currency_' + menuId));
  return currency;
}
