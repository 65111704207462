import React from 'react';
import { Link } from 'react-router-dom';
import CategoryImage from './../../components/CategoryImage';

function CategoryListV1(props) {
  const { mainCategories, onCategorySelected } = props;

  return (
    <div className="category-list category-list-v1">
      {mainCategories &&
        mainCategories.map((category) => {
          return (
            <div role="listitem" key={category.id} className="category" onClick={() => onCategorySelected(category.id)}>
              <Link to="menu">
                <CategoryImage category={category} title={category.name} />
                <div className="category-details">
                  <h3 className="title">{category.name}</h3>
                  {category.description && <p className="description">{category.description}</p>}
                </div>
              </Link>
            </div>
          );
        })}
    </div>
  );
}

export default CategoryListV1;
