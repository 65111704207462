import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Sidebar, useProSidebar } from 'react-pro-sidebar';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchShoppingCartDetails } from '../actions/shoppingCartActions';
import Coupon from './Coupon';
import FormatPrice from './FormatPrice';
import MiniCartItem from './MiniCartItem';
import useCart from '../hooks/useCart';

function MiniCart(props) {
  const { allowToOrder, miniCartButtonEanbled, shoppingCart } = useCart();

  const { collapseSidebar } = useProSidebar();
  const { t } = useTranslation();

  if (!allowToOrder) {
    return null;
  }

  return (
    <>
      <Sidebar collapsedWidth={0} width="100%" backgroundColor="white" defaultCollapsed="true">
        <div className="mini-cart-header">
          <h4>{t('Shopping Cart')}</h4>
          <span className="mini-cart-close" onClick={() => collapseSidebar()}>
            <img src="/assets/icon/close.svg" alt={t('Close')} />
          </span>
        </div>
        <div className="mini-cart-details">
          <div className="mini-cart-items">
            {shoppingCart &&
              shoppingCart.items?.map((item) => {
                return <MiniCartItem key={item.id} item={item} />;
              })}
          </div>
          {(shoppingCart === null || shoppingCart.items === null || shoppingCart.items?.length === 0) && (
            <div className="empty-cart">
              <img src="/assets/icon/cart_black.svg" alt="" />
              <h2>{t('Your cart is empty!')}</h2>
              <p>{t('Add something to enjoy your day :)')}</p>
            </div>
          )}
        </div>
        <div className="mini-cart-summary">
          <div className="mini-cart-summary-subtotal">
            <p className="subtotal-lable">{t('Sub Total')}:</p>
            <p className="subtotal-value">
              <FormatPrice value={shoppingCart?.total_items || 0} />
            </p>
          </div>

          <Coupon />

          <div className="mini-cart-summary-action">
            <Link to="../checkout" className={'btn-checkout' + (shoppingCart?.total_quantity > 0 ? '' : ' disabled')}>
              {t('Place Your Order')}
            </Link>
          </div>
        </div>
      </Sidebar>
      {miniCartButtonEanbled && (
        <div className="mini-cart" onClick={() => collapseSidebar()}>
          <img src="/assets/icon/cart.svg" alt="" />
          <p className="total-items">{shoppingCart?.total_quantity || 0}</p>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    qrcode: state.qrcode,
    menu: state.menu,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchShoppingCartDetails: fetchShoppingCartDetails,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MiniCart);
